import CardSectionHeader from 'components/ui/card-section-header'

function CardInfoB2B({ data }) {
  const dummy = [
    // {
    //   title: 'Potongan Harga',
    //   value: data.discount_type === 1 ? `Rp. ${onRupiah(data.discount)}` :  `${data.discount} %`,
    //   position: 'left' },
    { title: 'Jatuh Tempo Tembayaran', value: `H+ ${data?.due_date} Tagihan`, position: 'right' }
  ]
  return (
    <CardSectionHeader hideDivider>
      <div className="grid grid-cols-1 gap-3 md:gap-6">
        {dummy.map((item, index) => (
          <InfoContent key={index} title={item.title} value={item.value} position={item.position} />
        ))}
      </div>
    </CardSectionHeader>
  )
}

const InfoContent = ({ title, value, position }) => (
  <CardSectionHeader>
    <div className={`md:p-4 p-3 ${position === 'right' ? 'bg-gray-50' : 'bg-warning-50'}`}>
      <p className="text-md-regular text-gray-500 mb-2">{title}</p>
      <p
        className={`display-xs-semibold ${
          position === 'right' ? 'text-gray-900' : 'text-warning-700'
        }`}>
        {value}
      </p>
    </div>
  </CardSectionHeader>
)

export default CardInfoB2B
