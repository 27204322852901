import { Col, DatePicker, Row, Select, Typography } from 'antd'
import BadgeItem from 'components/ui/badge-item'
import CustomTable from 'components/ui/custom-table'
import { useMutation, useQuery } from 'react-query'
import {
  getLatestB2BReport,
  getReportList,
  getUsersReport,
  postGenerateB2BReport
} from '../service'
import { useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { onRupiah } from 'utils/formatCurrency'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
import CustomButton from 'components/ui/custom-button'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { FiRefreshCcw } from 'react-icons/fi'
import { LuDownload } from 'react-icons/lu'
import { useEffect } from 'react'
import axios from 'axios'
const monthFormat = 'MMM YYYY'
const initialFilter = {
  offset: 0,
  limit: 5,
  month: '',
  year: '',
  user_id: '',
  payment_status: ''
}

const optionStatus = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' }
]

const statusGenerateExcelColor = {
  success: 'text-success-500',
  failed: 'text-primary-500',
  'non-existent': 'text-gray-500',
  'in-progress': 'text-warning-500'
}

function ReportTypeBills() {
  const token = localStorage.getItem('token')
  const [reportData, setReportData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [listUser, setListUser] = useState([])
  const [filter, setFilter] = useState({ ...initialFilter })
  const [latestB2BReport, setLatestB2BReport] = useState({
    filepath: null,
    status: null,
    created_at: '',
    updated_at: ''
  })

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )

  const handleChangeSelectedFilter = (name, val) => {
    setFilter((prev) => ({
      ...prev,
      offset: 0,
      [name]: val
    }))
    return debouncedChangeHandler()
  }

  const handleChangeTanggal = (val) => {
    if (val !== null) {
      const selectedMonth = moment(val).format('MM')
      const selectedYear = moment(val).format('YYYY')
      setFilter((prev) => ({
        ...prev,
        month: selectedMonth,
        year: selectedYear,
        offset: 0
      }))
    } else {
      setFilter((prev) => ({
        ...prev,
        month: '',
        year: '',
        offset: 0
      }))
    }
    return debouncedChangeHandler()
  }
  const handleChangeFilterIncludeOffset = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
      offset: 0
    }))
    return debouncedChangeHandler()
  }
  const onChangeNextPage = () => {
    if (currentPage !== Math.ceil(reportData?.meta?.total / filter.limit)) {
      handleChangeSelectedFilter('offset', currentPage * filter.limit)
      setCurrentPage((prev) => prev + 1)
    }
  }
  const onChangePrevPage = () => {
    if (currentPage > 1) {
      handleChangeSelectedFilter('offset', filter.offset - filter.limit)
      setCurrentPage((prev) => prev - 1)
    }
  }

  const { refetch } = useQuery(['getReportList', debouncedChangeHandler], {
    queryFn: () => {
      const dataSend = {
        token: token,
        params: filter
      }

      return getReportList(dataSend)
    },
    onSuccess: (data) => {
      setReportData(data)
    },
    enabled: Boolean(debouncedChangeHandler)
  })
  useQuery('getListUser', getUsersReport, {
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setListUser(newData)
    }
  })

  const {
    refetch: refetchLatestB2BReport,
    isFetching: isFetchingLatestB2BReport,
    isRefetching: isRefetchingLatestB2BReport,
    isLoading: isLoadingLatestB2BReport
  } = useQuery({
    queryKey: 'getLatestB2BReport',
    queryFn: () => getLatestB2BReport(),
    onSuccess: (data) => {
      setLatestB2BReport(data)
      switch (data?.status) {
        case 'success':
          return showSuccessMessage('Data Excel Berhasil di generate')
        // case 'in-progress':
        //   return showWarningMessage('Menunggu Proses Generate Data ke Ms. Excel')
        case 'failed':
          return showErrorMessage('Proses generate gagal, silahkan coba kembali.')
        case 'non-existent':
        default:
          return ''
      }
    },
    onError: (err) => {
      showErrorMessage('Gagal mendapatkan data terakhir generate excel, Silahkan coba sesaat lagi.')
    },
    enabled: latestB2BReport?.status === 'in-progress',
    refetchOnWindowFocus: false,
    refetchInterval: 10000, //Refetch interval in ms (10sec)
    refetchIntervalInBackground: true
  })

  useEffect(() => {
    refetchLatestB2BReport()
  }, [])

  const generateB2BReportMutation = useMutation({
    mutationKey: 'generateB2BReport',
    mutationFn: () => {
      const dataSend = {
        token: token,
        params: {
          month: filter.month,
          year: filter.year,
          user_id: filter.user_id,
          payment_status: filter.payment_status
        }
      }
      return postGenerateB2BReport(dataSend)
    },
    onSuccess: (data) => {
      showSuccessMessage(
        'Generate Excel sedang dalam antrian, silahkan tunggu hingga proses selesai'
      )
      return refetchLatestB2BReport()
    },
    onError: () => {
      showErrorMessage('Generate Excel Gagal, Silahkan coba lagi')
    }
  })

  function handleDownloadExcelB2BReport() {
    // axios
    //   .get(`${process.env.REACT_APP_IMAGE_BASE_URL}${latestB2BReport?.filepath}`, {
    //     headers: {
    //       'Content-Type': 'application/octet-stream'
    //     },
    //     responseType: 'blob'
    //   })
    //   .then((response) => {
    //     const a = document.createElement('a')
    //     const url = window.URL.createObjectURL(response.data)
    //     a.href = url
    //     a.download = `B2B Report.${latestB2BReport?.filepath?.split('/')[1]?.split('.')[1]}`
    //     a.click()
    //   })
    //   .catch((err) => {
    //     console.log('error', err)
    //   })
    const link = document.createElement('a')
    link.href = `${process.env.REACT_APP_IMAGE_BASE_URL}${latestB2BReport?.filepath}`
    const fileName = `B2B Report.${latestB2BReport?.filepath?.split('/')[1]?.split('.')[1]}`
    link.setAttribute('download', fileName)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  const columns = [
    {
      name: 'Tanggal',
      cell: (row) => moment(row.created_at).format('DD MMM YYYY'),
      sortable: true
    },
    {
      name: 'Nama Unit',
      selector: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="text-sm-medium"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <p className="text-xs-regular mt-1">{row.unit_police_number}</p>
        </div>
      ),
      sortable: true,
      width: '30%'
    },
    {
      name: 'User',
      selector: (row) => (
        <Typography.Text
          className="text-sm-medium"
          ellipsis={{
            tooltip: row.user_fullname
          }}>
          {row.user_fullname}
        </Typography.Text>
      ),
      sortable: true
    },
    {
      name: 'Layanan',
      selector: (row) => row.transaction_type,
      sortable: true,
      width: '13%'
    },
    {
      name: 'Status',
      selector: (row) => row.payment_status,
      cell: (row) => (
        <BadgeItem type={row.payment_status === 'Unpaid' ? 'primary' : 'success'}>
          {row.payment_status}
        </BadgeItem>
      ),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Biaya',
      selector: (row) => `Rp. ${onRupiah(row.transaction_profit)}`,
      sortable: true
    }
    // {
    // 	name: 'Action',
    // 	selector: row => row.id,
    // 	cell: (row) => <>

    // 	</>,
    // 	sortable: true,
    // },
  ]

  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="text-xl-medium text-gray-900">
        <Link className=" stroke-primary-600 text-gray-700 hover:text-primary-500" to={'/report'}>
          Report
        </Link>{' '}
        / <span className="text-primary-500">{`Total Tagihan`}</span>
      </Col>
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* filter setting */}
        <Col span={24}>
          <div className="flex gap-2">
            {/* <Select
              placeholder="Bulan"
              options={months}
              onChange={(val) => handleChangeSelectedFilter('month', val)}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
            />
            <DatePicker
              placeholder="Tahun"
              onChange={(_, dateString) => handleChangeSelectedFilter('year', dateString)}
              picker="year"
              allowClear
            /> */}
            <DatePicker
              placeholder="Pilih Bulan & Tahun"
              className="rounded-md"
              allowClear
              format={monthFormat}
              picker="month"
              onChange={(val) => handleChangeTanggal(val)}
            />
            <Select
              placeholder="User"
              options={listUser}
              onChange={(val) => handleChangeSelectedFilter('user_id', val)}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            />
            <Select
              placeholder="Status"
              options={optionStatus}
              onChange={(val) => handleChangeSelectedFilter('payment_status', val)}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            />
          </div>
        </Col>
        {/* table content */}
        <div className="md:bg-white md:border border-solid border-gray-200 md:p-6 rounded-[4px]">
          <div className="flex flex-col lg:flex-row gap-3 lg:gap-0 justify-between lg:items-center p-3 lg:p-0 lg:mb-6">
            <p className="display-xs-medium text-gray-900  h-full">{`Daftar Tagihan`}</p>
            {/* Wording akan berubah sesuai Status */}
            {/* <span></span> */}
            <div className="flex flex-col gap-2 lg:flex-row">
              <span className="flex lg:justify-end items-center">
                {statusGenerateExcel(latestB2BReport)}
              </span>
              <div className="flex gap-2 justify-start lg:justify-center items-center lg:items-center">
                <div className="w-full h-full flex justify-center items-center">
                  <AiOutlineExclamationCircle
                    className={`${
                      statusGenerateExcelColor?.[latestB2BReport?.status] ?? 'text-gray-700'
                    }`}
                    size={20}
                  />
                </div>
                <CustomButton
                  type="plain"
                  disabled={latestB2BReport?.status === 'in-progress'}
                  onClick={() => generateB2BReportMutation.mutate()}
                  label={
                    <div className="w-full h-full flex justify-center items-center gap-2">
                      <FiRefreshCcw className={'text-gray-700'} size={18} />{' '}
                      <span className="hidden md:block">Generate Excel</span>
                    </div>
                  }
                />
                <CustomButton
                  onClick={handleDownloadExcelB2BReport}
                  type="plain"
                  disabled={latestB2BReport?.status !== 'success'}
                  label={
                    <div className="w-full h-full flex justify-center items-center gap-2">
                      <LuDownload className={`text-gray-700`} size={20} />
                      <span className="hidden md:block">Download Excel</span>
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <CustomTable
            columns={columns}
            data={reportData?.data || []}
            currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
            totalPerPage={
              currentPage * filter?.limit >= reportData?.meta.total
                ? reportData?.meta.total
                : currentPage * filter?.limit
            }
            lengthAllData={reportData?.meta?.total}
            defaultPageSize={filter?.limit}
            onChangePageSize={handleChangeFilterIncludeOffset}
            onChangeNextPage={onChangeNextPage}
            onChangePrevPage={onChangePrevPage}
          />
        </div>
      </Col>
    </Row>
  )
}

function statusGenerateExcel(latestB2BReport) {
  switch (latestB2BReport?.status) {
    case 'success':
      return `Terakhir di generate ${moment(latestB2BReport?.created_at).format('LLL')}`
    case 'in-progress':
      return 'Menunggu Proses Generate Data ke Ms. Excel'
    case 'failed':
      return 'Proses generate gagal, silahkan coba kembali.'
    case 'non-existent':
    default:
      return ''
  }
}

export default ReportTypeBills
