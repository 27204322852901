import { Select } from 'antd'
import React from 'react'

function FormSelect({ data }) {
  const { label, mandatory, ...selectProps } = data
  return (
    <div className="grid grid-cols-1 gap-2">
      <p className="text-sm-medium text-gray-700">
        {label} {mandatory ? <span className="text-danger-500">*</span> : null}
      </p>
      <Select
        {...selectProps}
        listItemHeight={5}
        listHeight={250}
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  )
}

export default FormSelect
