import CustomButton from 'components/ui/custom-button'
import FormInput from 'components/ui/form/form-input'
import { EDIT } from 'data/constants'
import { ModalLayout } from 'layouts'
import useValidation from 'lib/useValidation'
import React from 'react'
import { useState } from 'react'

const ModalCreateEditEmail = ({ onCancel, show, type, data, onSubmit }) => {
  const { emailValidation } = useValidation()
  const [email, setEmail] = useState({
    errorMessage: null,
    value: data ?? '',
    isValid: false
  })

  function handleChange(value) {
    const validationResult = emailValidation(value)
    if (value == '') {
      return setEmail((prev) => ({
        ...prev,
        isValid: false,
        value: value,
        errorMessage: 'Email Harus diisi'
      }))
    } else if (validationResult === '') {
      setEmail((prev) => ({ ...prev, isValid: true, value: value, errorMessage: '' }))
    } else {
      setEmail((prev) => ({
        ...prev,
        isValid: false,
        value: value,
        errorMessage: validationResult
      }))
    }
  }
  function handleSubmitEmail() {
    if (email?.value?.replace(' ', '') == '') {
      return setEmail((prev) => ({
        ...prev,
        isValid: false,
        value: email.value,
        errorMessage: 'Email Harus diisi'
      }))
    }
    onSubmit(email.value)
  }
  return (
    <ModalLayout
      headerTitle={type === EDIT ? 'Edit Email' : 'Tambah Email'}
      onCancel={onCancel}
      openModal={show}
      className="max-w-[500px] w-full">
      <FormInput
        data={{
          name: 'email',
          type: 'email',
          id: 'email',
          mandatory: true,
          placeholder: 'Masukkan Email',
          label: 'Email',
          value: email.value,
          onChange: (e) => {
            handleChange(e.target.value)
          }
        }}
      />
      {email?.errorMessage ? (
        <small className="text-primary-300">{email?.errorMessage}</small>
      ) : null}

      <div className="flex justify-end items-center gap-2 mt-4">
        <CustomButton type="plain" label={'Batal'} onClick={onCancel} />
        <CustomButton label={'Simpan'} disabled={!email.isValid} onClick={handleSubmitEmail} />
      </div>
    </ModalLayout>
  )
}

export default ModalCreateEditEmail
