import React, { useState } from 'react'
// Component
import { Col, Row } from 'antd'
import { BirdMobilReverse, FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from 'assets'
import FooterItem from './footer-item'
import { bantuanPanduanNav, layananNav, jenisMobilNav } from 'data/footer-nav-list'
import { merk } from 'data/merk'
import { useQuery } from 'react-query'
import { getAllBrands } from 'services/opt-spec-car'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const initialFilter = {
  province: null,
  city: null,
  location: null,
  merek: null,
  model: null,
  transmisi: null,
  bahanBakar: null,
  warna: null,
  tipe: null,
  minHarga: null,
  maxHarga: null,
  minTahun: null,
  maxTahun: null,
  minTempuh: null,
  maxTempuh: null,
  keywords: null,
  limit: 15,
  offset: 0
}
function Footer() {
  const { push } = useHistory()
  const [brands, setBrands] = useState([])
  const [filter, setFilter] = useState({ ...initialFilter })
  useQuery({
    queryKey: 'getBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    },
    staleTime: 0,
    refetchOnWindowFocus: false
  })
  function handleClickBrandPrice(name, value) {
    let brandObj = {}
    if (name === 'merek') {
      brandObj = brands.find((data) => data.label.toLowerCase() === value.label.toLowerCase())
    }
    let newObj = { ...filter, [name]: brandObj }
    if (window.location.pathname.includes('/beli/ex-taxi')) {
      push('/beli/ex-taxi', {
        data: {
          ...newObj
        }
      })
    } else
      push('/beli/mobil-bekas', {
        data: {
          ...newObj
        }
      })
  }
  return (
    <>
      <Row justify="center" className="h-full py-9 md:pb-5 bg-primary-500">
        <Col span={22} lg={{ span: 20 }} className=" mx-auto grid grid-cols-1 gap-5 md:grid-cols-4">
          {/* Grid 1 */}
          <div className="flex flex-col gap-4 md:gap-10">
            <div className="flex items-center gap-2">
              <BirdMobilReverse />
              <span className="text-xl-semibold text-white">BirdMobil</span>
            </div>
            <div className="text-xs-regular md:text-md-regular text-primary-100 flex flex-col gap-2 px-2">
              <p>
                Jl. Mampang Prapatan No 60, Tegal Parang, Kec. Mampang Prapatan. Jakarta Selatan,
                DKI Jakarta 12790
              </p>
              <p>
                BirdMobil BSD : <br /> Jl. Cilenggang Raya No 30, Cilenggang, Tangerang, Kota
                Tangerang Selatan, Banten 15310
              </p>
              <p>Phone : 021-79171000</p>
              <p>Contact Center : 0813-9981-4478</p>
              <p>email : account.executive@birdmobil.id</p>
              <p>marketing.pmm@bluebirdgroup.com</p>
            </div>
          </div>
          {/* Grid 2 - 4 */}
          {/* LAYANAN */}
          <div className="flex-col gap-5 hidden md:flex">
            <p className="text-sm-semibold text-primary-300">{layananNav.title}</p>
            <div className="flex flex-col gap-4">
              {layananNav.nav.map((navItem) => (
                <div key={navItem.title} className="min-w-min">
                  <FooterItem
                    className={'hover:text-primary-300 text-primary-100 '}
                    label={navItem.title}
                    to={navItem.url}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* JENIS MOBIL */}
          <div className="hidden md:flex flex-col gap-5">
            <p className="text-sm-semibold text-primary-300">Merk Mobil</p>
            <div className="flex flex-col gap-4">
              {merk.map((merkItem, index) => (
                <div key={index} className="min-w-min">
                  <span
                    onClick={() => handleClickBrandPrice('merek', merkItem)}
                    className="hover:text-primary-300 text-primary-100 text-sm-medium cursor-pointer">
                    {merkItem.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
          {/* BANTUAN DAN PANDUAN */}
          <div className="flex flex-col gap-5">
            <p className="text-sm-semibold text-primary-300">{bantuanPanduanNav.title}</p>
            <div className="flex flex-col gap-4">
              {bantuanPanduanNav.nav.map((navItem) => (
                <div key={navItem.title} className="min-w-min">
                  <FooterItem
                    className={'hover:text-primary-300 text-primary-100 '}
                    label={navItem.title}
                    to={navItem.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <Row justify="center" className="min-h-[80px] bg-gray-900">
        <Col
          span={20}
          className="flex flex-col-reverse justify-around py-4 md:py-0 md:flex-row md:justify-between md:items-center">
          <div className="text-md-normal text-primary-300">
            © 2023 BirdMobil. All right reserved.
          </div>
          <div className="flex gap-5 items-center">
            {/* <a href="/" rel="noreferrer" target="_blank">
              <TwitterIcon />
            </a>
            <a href="/" rel="noreferrer" target="_blank">
              <LinkedinIcon />
            </a> */}
            <a
              href="https://www.facebook.com/profile.php?id=100082489435425"
              rel="noreferrer"
              target="_blank">
              <FacebookIcon className={'fill-[#F6937A]'} />
            </a>
            <a href="https://www.instagram.com/birdmobil/" rel="noreferrer" target="_blank">
              <InstagramIcon className={'fill-[#F6937A]'} />
            </a>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Footer
