import React from 'react'
import { Divider } from 'antd'
import { BirdMobilLogo, FacebookIcon, InstagramIcon } from 'assets'
import { onRupiah } from 'utils/formatCurrency'
import { DefaultInvoiceContent, ServiceInvoiceContent } from './invoice-content-types'
import { accountNumberBirdMobil } from 'data/accountNumber'
import moment from 'moment'

const InvoiceContent = React.forwardRef(({ data }, ref) => {
  const { invoiceData } = data
  const invoiceContentMap = {
    Servis: (
      <ServiceInvoiceContent
        data={data}
        dataSpareparts={invoiceData?.invoice_sparepart_items}
        dataLayanans={invoiceData?.invoice_layanan_items}
      />
    ),
    Beli: <DefaultInvoiceContent invoiceData={invoiceData} />,
    Booking: <DefaultInvoiceContent invoiceData={invoiceData} />,
    Inspeksi: <DefaultInvoiceContent invoiceData={invoiceData} />
  }
  return (
    <div className="w-full h-full p-5">
      <div ref={ref} className="grid grid-cols-1 gap-5">
        <div className="flex justify-between items-center">
          <img src={BirdMobilLogo} className="w-full max-w-[100px] object-contain" />
          <p className=" text-gray-500">
            {invoiceData?.invoice_service_type === 'Servis'
              ? data?.user_id > 0 &&
                data?.company_id == 0 &&
                data?.booking_invoice?.transaction_detail?.is_payment_completed
                ? moment(data?.booking_invoice?.transaction_detail?.paid_at).format('LL')
                : data?.user_id > 0 && data?.company_id > 0 && data?.status === 'SERVICE_FINISHED'
                ? moment(data?.completion_time).format('LL')
                : null
              : invoiceData?.invoice_date}
          </p>
        </div>
        <Divider className="m-0" />
        <div className="mb-5 flex flex-col gap-2">
          <div className="flex justify-between ">
            <p className="text-gray-500">Invoice No.</p>
            <p className="text-gray-700">{invoiceData?.invoice_no}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-gray-500">Account</p>
            <p className="text-gray-700">
              {invoiceData?.invoice_service_type === 'Servis'
                ? data?.user_id > 0 && data?.company_id == 0
                  ? invoiceData?.invoice_account
                  : // Admin PT B2B
                  data?.user_id > 0 &&
                    data?.company_id > 0 &&
                    data?.user_id == data?.company_admin_id
                  ? data?.company?.name
                  : // Karyawan PT B2B
                  data?.user_id > 0 &&
                    data?.company_id > 0 &&
                    data?.user_id !== data?.company_admin_id
                  ? `${data?.user?.fullname} - ${data?.company?.name}`
                  : ''
                : invoiceData?.invoice_account}
            </p>
          </div>
          {data?.user_id > 0 && data?.company_id > 0 ? (
            <div className="flex justify-between ">
              <p className="text-gray-500 whitespace-nowrap">Alamat</p>
              <p className="text-gray-700  text-right">{data?.company?.address}</p>
            </div>
          ) : null}
          <div className="flex justify-between ">
            <p className="text-gray-500">Unit</p>
            <p className="text-gray-700">{invoiceData?.invoice_unit_name}</p>
          </div>
          {invoiceData.invoice_service_type === 'Servis' ? (
            <>
              <div className="flex justify-between ">
                <p className="text-gray-500">Plat No</p>
                <p className="text-gray-700">{data?.police_number}</p>
              </div>
              <div className="flex justify-between ">
                <p className="text-gray-500">Kilometer</p>
                <p className="text-gray-700">{data?.mileage}</p>
              </div>
            </>
          ) : null}
          <div className="flex justify-between ">
            <p className="text-gray-500">Layanan</p>
            <p className="text-gray-700">{invoiceData?.invoice_service_type}</p>
          </div>
        </div>
        {invoiceContentMap[invoiceData.invoice_service_type]}
        <div className="text-gray-900 grid grid-cols-1 font-semibold gap-3 my-5">
          <div className="flex justify-between">
            <p>Subtotal :</p>
            <p>Rp. {onRupiah(invoiceData?.invoice_subtotal)}</p>
          </div>
          {invoiceData?.invoice_discount !== null && (
            <div className="flex justify-between">
              <p>Discount :</p>
              <p>- Rp. {onRupiah(invoiceData?.invoice_discount)}</p>
            </div>
          )}
          {invoiceData?.invoice_discount_voucher !== undefined &&
            invoiceData?.invoice_discount_voucher !== null && (
              <div className="flex justify-between">
                <p>Discount Voucher :</p>
                <p>- Rp. {onRupiah(invoiceData?.invoice_discount_voucher)}</p>
              </div>
            )}
          {invoiceData?.invoice_discount_company !== undefined &&
            invoiceData?.invoice_discount_company !== null && (
              <div className="flex justify-between">
                <p>Discount Perusahaan :</p>
                <p className="text-primary-500">
                  - Rp. {onRupiah(invoiceData?.invoice_discount_company)}
                </p>
              </div>
            )}
          {invoiceData?.invoice_admin_fee !== 0 && (
            <div className="flex justify-between">
              <p>Biaya Admin :</p>
              <p>Rp. {onRupiah(invoiceData?.invoice_admin_fee)}</p>
            </div>
          )}
          <div className="flex justify-between">
            <p>Pajak :</p>
            <p>Rp. {onRupiah(invoiceData?.invoice_tax)}</p>
          </div>
          {invoiceData?.invoice_range_price !== 0 && (
            <div className="flex justify-between">
              <p>Biaya Jarak :</p>
              <p>Rp. {onRupiah(invoiceData?.invoice_range_price)}</p>
            </div>
          )}
          <div className="grid grid-flow-col text-primary-500 text-lg">
            <p>Total Pembayaran</p>
            <p className="text-right">Rp. {onRupiah(invoiceData?.invoice_total)}</p>
          </div>
        </div>
        {invoiceData?.invoice_service_type === 'Servis' ? (
          <>
            {data?.user_id > 0 && data?.company_id > 0 ? (
              <div className="flex flex-col gap-3 ">
                <span className={'font-semibold text-gray-900'}>Rekening Pembayaran</span>
                {accountNumberBirdMobil?.map((bank) => (
                  <div
                    key={`bank-${bank?.id}`}
                    className="bg-gray-100 flex justify-between items-center p-4 rounded-lg">
                    <div className={`text-gray-700 font-normal flex flex-col gap-2`}>
                      <span>Bank {bank?.bankName}</span>
                      <span>{bank?.accountName}</span>
                    </div>
                    <span className={'font-semibold text-gray-900'}>{bank?.accountNumber}</span>
                  </div>
                ))}
              </div>
            ) : null}
            <div className="pt-3 flex flex-col gap-2">
              <span className={'font-semibold text-gray-900'}>Catatan Service</span>
              <p className={'font-normal text-gray-700'}>{data.notes ? data.notes : 'Tidak Ada'}</p>
            </div>

            {/* Signature Head Finance BirdMobil */}
            {data?.user_id > 0 && data?.company_id > 0 ? (
              <div className="mt-8 h-[100px] flex items-end">
                <span className={'font-normal text-gray-700'}>( Head Finance BirdMobil )</span>
              </div>
            ) : null}
          </>
        ) : null}
        <div className="text-sm-regular text-gray-700">
          <p className="mb-5 font-semibold">Thank you for your business</p>
          <p className="text-sm-medium mb-2">{invoiceData?.invoice_workshop_name}</p>
          <p className="text-gray-500">{invoiceData?.invoice_workshop_address}</p>
        </div>
        <Divider className="m-0" />
        <div className="flex w-full gap-5 items-center justify-around">
          {/*
              <TwitterIcon />
              <LinkedinIcon />
             */}
          <FacebookIcon className={'fill-gray-400'} />
          <InstagramIcon className={'fill-gray-400'} />
        </div>
      </div>
    </div>
  )
})
InvoiceContent.displayName = 'Invoice Content'
export default InvoiceContent
