/* eslint-disable no-undef */
import API from 'services'

const getProvinces = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/provinces/all`
  )
  return response.data
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/${provinceId}`
  )
  return response.data
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/subdistricts/city/${cityId}`
  )
  return response.data
}

// otp
const getOTPChangePassword = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/auth/otp/send`,
    {
      params: args.params
    }
  )
  return response.data
}
const sendOTPChangePassword = async (args) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/auth/otp/validate`,
    args.body
  )
  return response
}
const sendChangePassword = async (args) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/auth/submit-new-password`,
    args.body
  )
  return response
}
// args.account_type :[personal, b2b]
const getDataUser = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/${args.account_type}/profile`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  )
  return response.data
}
const editDataUser = async (args) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/${args.account_type}/profile`,
    args.body,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  )
  return response
}
const deleteDataUser = async (args) => {
  const { data: response } = await API.delete(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/${args.account_type}/profile`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  )
  return response
}

const getEmailListB2B = async ({ token }) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/emails`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

const postEmailB2B = async (email) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/emails`,
    {
      email: email
    }
  )
  return response
}
const putEmailB2B = async ({ email, id }) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/emails/${id}`,
    {
      email: email
    }
  )
  return response
}

const deleteEmailB2B = async (id) => {
  const { data: response } = await API.delete(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/emails/${id}`
  )
  return response
}

export {
  getEmailListB2B,
  getProvinces,
  getCities,
  getSubdistricts,
  sendChangePassword,
  getOTPChangePassword,
  sendOTPChangePassword,
  postEmailB2B,
  getDataUser,
  deleteDataUser,
  editDataUser,
  putEmailB2B,
  deleteEmailB2B
}
