import { Col, Divider, Input, Row, Spin, Steps } from 'antd'
import moment from 'moment'
import {
  CardServiceCart,
  FormDataServis,
  FormJadwalServis,
  FormLayananServis,
  FormReviewServis
} from 'features/servis'
import { postBookingService } from 'features/servis/service'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  useCartService,
  useDataService,
  useJadwalService,
  useServicePackets,
  useVoucher
} from 'features/servis/hooks'
import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import BadgeItem from 'components/ui/badge-item'
import MobileStep from 'components/ui/mobile-step'
import { showErrorMessage } from 'utils/toastMessage'
import { onRupiah } from 'utils/formatCurrency'
import { XCircleIcon } from 'assets'
import 'react-quill/dist/quill.snow.css'
const items = [
  { key: 0, title: 'Data Servis' },
  { key: 1, title: 'Layanan Servis' },
  { key: 2, title: 'Jadwal Servis' },
  { key: 3, title: 'Review' }
]

function FormServicePage() {
  const { replace, goBack } = useHistory()
  const [currentContent, setCurrentContent] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const {
    citiesList,
    subdistrictsList,
    provincesList,
    dataUser,
    units,
    detailSelectedCar,
    errors,
    selectedCar,
    keywords,
    handleChangeKeyword,
    setDataUser,
    handleSelectedCar,
    handleChange,
    selectedTypeId,
    handleValidationDataService,
    handleChangeLocationDataService
  } = useDataService(currentContent)
  const {
    isShowRoom,
    setIsShowRoom,
    homeState,
    schedule,
    times,
    provinces,
    cities,
    subdistricts,
    workshops,
    rangePrice,
    handleChangeActiveWorkshop,
    handleChangeHomeData,
    handleChangeLocation,
    handleChangeSchedule,
    handleValidationJadwalService,
    scheduleIsValid
  } = useJadwalService(setDataUser, currentContent)

  const { cartList, cartIsLoading, handleSimulateCartService, deleteCartList } = useCartService()
  const {
    voucherList,
    selectedVoucher,
    resetVoucherState,
    modalVoucherOpen,
    handleModalVoucherOpen,
    modalDetailCoupon,
    handleModalDetailCoupon,
    handleVoucherCode,
    handleSelectedDetailCoupon,
    selectedDetailCoupon,
    handleModalVoucherClose,
    checkIsVoucherValid,
    temporarySelectedVoucher,
    temporarySelectedVoucherIds,
    handleTemporarySelectedVoucher,
    applySelectedVouchers,
    resetTemporarySelectedVouchers,
    selectedVoucherIds,
    temporaryVoucherCode,
    setTemporaryVoucherCode
  } = useVoucher('Servis', cartList?.price_total_before)
  const {
    removeCartItem,
    handlePacketService,
    handleGeneralService,
    deleteAllServices,
    services,
    servicePackets,
    servicesGenerals,
    selectedGeneralServiceIds,
    selectedPacketIds,
    handleValidationPacketsService,
    changeKeywordHandler,
    keyword,
    handleSelectedCategory,
    selectedCategory,
    serviceCategories,
    handleTampilLebihBanyak,
    servicesIsFetching,
    isAddServisBtnDisabled
  } = useServicePackets(selectedTypeId, resetVoucherState)
  useEffect(() => {
    resetVoucherState()
    deleteAllServices()
    deleteCartList()
  }, [detailSelectedCar?.id])
  useEffect(() => {
    if (servicePackets.length > 0 || servicesGenerals.length > 0) {
      handleSimulateCartService(
        homeState.range_price,
        selectedVoucherIds,
        servicePackets,
        servicesGenerals,
        selectedCar.id
      )
    } else {
      deleteCartList()
      // resetVoucherState()
    }
  }, [
    homeState.range_price?.value,
    selectedVoucherIds,
    selectedGeneralServiceIds,
    servicePackets,
    servicesGenerals
  ])
  // FETCHING DATA
  const postService = useMutation(postBookingService, {
    onSuccess: (data) => {
      replace('/servis/servis-mobil/result', {
        data: {
          workshop_name: dataUser.workshop?.name,
          date: moment(schedule.date).format('DD MMM YYYY'),
          hours: schedule?.hours?.time,
          status: data?.status
        }
      })
    },
    onError: () => {
      showErrorMessage('Terjadi kesalahan, silahkan coba lagi atau kontak Customer Service')
    }
  })

  // FUNCTIONS

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [currentContent])
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  function handleNextPage(contentId) {
    let valid = { status: true, errorMessage: '' }
    if (currentContent === 0) {
      valid = handleValidationDataService()
    } else if (currentContent === 1) {
      valid = handleValidationPacketsService()
    } else if (currentContent === 2) {
      valid = handleValidationJadwalService(dataUser?.workshop?.id)
    }
    // Pass Validation
    if (!valid.status) {
      return showErrorMessage(valid.errorMessage)
    }
    setCurrentContent(contentId)
  }

  // FORM REVIEW FUNCTIONS
  function handleCheckout() {
    if (!scheduleIsValid()) {
      showErrorMessage(
        `Silahkan memilih ulang jam ${isShowRoom ? 'datang ke bengkel' : 'pickup'} Anda`
      )
      handleNextPage(2)
    }

    // HANDLE OBJECT SERVICE_PACKETS FOR BODY BOOKING API
    const newServicePackets = []
    servicePackets.length > 0
      ? servicePackets.map((packet) => {
          const newGeneralServices = []
          packet.general_services.map((service) => {
            const newGeneralObj = {
              general_service_id: service.general_service.id,
              sparepart_id: service.sparepart.id
            }
            newGeneralServices.push(newGeneralObj)
          })

          const newObj = {
            service_id: packet.service_id,
            packet_id: packet.packet_id,
            general_services: newGeneralServices
          }
          newServicePackets.push(newObj)
        })
      : null

    // HANDLE OBJECT SERVICE_GENERALS FOR BODY BOOKING API
    const newServiceGenerals = []
    servicesGenerals.length > 0
      ? servicesGenerals.map((service) => {
          const newGeneralService = {
            service_id: service.service_id,
            general_service_id: service.general_service.id,
            sparepart_id: service.sparepart.id
          }
          newServiceGenerals.push(newGeneralService)
        })
      : null
    // SPLIT Jadwal Hour
    const newSplitHour = schedule.hours.time.split('-')

    // Merge Selected Voucher Ids
    const newVoucherIdsArr =
      Object.keys(selectedVoucherIds).length > 0
        ? Object.keys(selectedVoucherIds).map((id) => parseInt(id))
        : []

    const dataSend = {
      full_name: dataUser.nama,
      unit_id: selectedCar.id,
      phone: dataUser.phone,
      email: dataUser.email,
      address: dataUser.alamat,
      booking_type: !isShowRoom ? 'PICKUP' : 'SERVICE',
      service_date: moment(schedule.date).format('YYYY-MM-DD'),
      service_start_time: newSplitHour[0],
      service_end_time: newSplitHour[1],
      workshop_id: dataUser.workshop.id,
      service_packets: newServicePackets,
      service_generals: newServiceGenerals,
      voucher_ids: newVoucherIdsArr,
      service_province_id: dataUser?.province?.value,
      service_city_id: dataUser?.city?.value,
      service_subdistrict_id: dataUser?.subdistrict?.value,
      service_postal_code: dataUser.postal_code
    }
    if (!isShowRoom) {
      dataSend.province_id = homeState?.province?.value
      dataSend.city_id = homeState?.city?.value
      dataSend.subdistrict_id = homeState?.subdistrict?.value
      dataSend.pin_google_map = `https://maps.google.com/?q=${homeState?.loc?.lat},${homeState?.loc?.lng}`
      dataSend.distance_id = homeState?.range_price?.value
      dataSend.pickup_address = homeState?.address
      dataSend.postal_code = homeState?.postal_code
    }
    postService.mutate(dataSend)
  }

  return (
    <Row className="py-10 gap-6 ">
      {/* Header Title */}
      <div className="text-center w-full display-sm-semibold text-gray-900">Servis Mobil</div>
      {/* Steps */}
      <Col span={22} lg={{ span: 20 }} className="lg:grid hidden grid-cols-1 w-full mx-auto">
        <Steps labelPlacement="vertical" current={currentContent} items={items} />
      </Col>
      <Col
        span={22}
        lg={{ span: 20 }}
        className="grid lg:hidden grid-cols-1 place-content-center place-items-center w-full mx-auto">
        <MobileStep current={currentContent} items={items} />
      </Col>
      {/* Content */}
      <Col span={22} lg={{ span: 20 }} className="grid grid-cols-1 lg:flex gap-6 mx-auto">
        <Col span={24} lg={{ span: 14 }} xl={{ span: 16 }}>
          {currentContent === 3 ? (
            <FormReviewServis
              postService={postService}
              dataUser={dataUser}
              schedule={schedule}
              selectedCar={selectedCar}
              dataPacketService={servicePackets}
              dataServiceGenerals={servicesGenerals}
            />
          ) : currentContent === 2 ? (
            <FormJadwalServis
              isPickup={true}
              isShowRoom={isShowRoom}
              setIsShowRoom={setIsShowRoom}
              state={dataUser}
              homeState={homeState}
              schedule={schedule}
              times={times}
              provinces={provinces}
              cities={cities}
              subdistricts={subdistricts}
              workshops={workshops}
              rangePrice={rangePrice}
              handleChangeActiveWorkshop={handleChangeActiveWorkshop}
              handleChangeData={handleChange}
              handleChangeHomeData={handleChangeHomeData}
              handleChangeLocation={handleChangeLocation}
              handleChangeSchedule={handleChangeSchedule}
            />
          ) : currentContent === 1 ? (
            <FormLayananServis
              keyword={keyword}
              isAddServisBtnDisabled={isAddServisBtnDisabled}
              handleTampilLebihBanyak={handleTampilLebihBanyak}
              changeKeywordHandler={changeKeywordHandler}
              cartIsLoading={cartIsLoading}
              services={services}
              servicesIsFetching={servicesIsFetching}
              selectedGeneralServiceIds={selectedGeneralServiceIds}
              selectedPacketIds={selectedPacketIds}
              handlePacketService={handlePacketService}
              handleGeneralService={handleGeneralService}
              handleSelectedCategory={handleSelectedCategory}
              selectedCategory={selectedCategory}
              serviceCategories={serviceCategories}
            />
          ) : (
            <FormDataServis
              handleChangeLocation={handleChangeLocationDataService}
              citiesList={citiesList}
              subdistrictsList={subdistrictsList}
              provincesList={provincesList}
              selectedCar={selectedCar}
              handleSelectedCar={handleSelectedCar}
              dataUser={dataUser}
              dataError={errors}
              cars={units}
              keyword={keywords}
              handleChangeKeyword={handleChangeKeyword}
              handleChange={handleChange}
            />
          )}
          {/* Button */}
        </Col>

        {/* Service Cart */}
        <Col span={24} lg={{ span: 10 }} xl={{ span: 8 }}>
          <CardServiceCart
            cartList={cartList}
            removeCartItem={removeCartItem}
            selectedVoucher={selectedVoucher}
            handleModalVoucherOpen={handleModalVoucherOpen}
          />
        </Col>
      </Col>

      {/* Button Back & Next */}
      <Col span={20} className="mx-auto">
        <Col span={24} lg={{ span: 16 }} className="flex gap-3 justify-end items-center">
          <CustomButton
            type="secondary"
            label={currentContent === 0 ? 'Batal' : 'Sebelumnya'}
            onClick={() => {
              currentContent === 0 ? goBack() : setCurrentContent((prev) => prev - 1)
            }}
          />
          <CustomButton
            label={currentContent === 3 ? 'Checkout' : 'Selanjutnya'}
            onClick={() =>
              currentContent === 3 ? handleOpenModal() : handleNextPage(currentContent + 1)
            }
          />
        </Col>
      </Col>
      {/* MODAL GET SERVICES AFTER SELECT CAR TO SERVIS */}
      {/* <ModalLayout
        openModal={getServiceIsLoading}
        closable={false}
        className={'w-full max-w-sm'}
        headerTitle={'Memuat Layanan Servis'}>
        <div className="flex gap-2 items-center justify-center">
          <Spin spinning={getServiceIsLoading}></Spin>
          <p className="text-md-medium text-gray-600">Memuat layanan servis</p>
        </div>
      </ModalLayout> */}

      {/* CONFIRMATION MODAL */}
      <ModalLayout
        openModal={openModal}
        headerTitle="Konfirmasi Servis"
        onCancel={handleCloseModal}
        className={'w-full max-w-md'}>
        <div className="grid grid-cols-1 gap-5">
          {/* Data Mobil */}
          <div className="p-4 flex-col flex rounded-[4px] gap-2 border-gray-200 border">
            {selectedCar?.unit_ownership === 'Mobil Perusahaan' ? (
              <BadgeItem type="purple" className={'max-w-fit'}>
                Mobil Perusahaan
              </BadgeItem>
            ) : (
              <BadgeItem type="plain" className={'max-w-fit'}>
                Mobil Pribadi
              </BadgeItem>
            )}
            <p className="text-md-medium text-gray-900">{selectedCar?.unit_name}</p>
          </div>
          <Divider className="m-0" />
          {/* Rincian Servis */}
          <div className="flex flex-col gap-2">
            <p className="text-md-medium text-gray-500 ">Rincian Servis:</p>
            {/* TODO: List Map */}
            <ol className="flex flex-col gap-2">
              {servicePackets?.map((packet) =>
                packet?.general_services?.map((el) => (
                  <li key={el.general_service.id} className="flex flex-col gap-1">
                    <p className="text-sm-medium text-gray-700">{el.general_service.name}</p>
                    <p className="text-xs-regular text-gray-500">{el.sparepart.name}</p>
                  </li>
                ))
              )}
              {servicesGenerals?.map((service) => (
                <li key={service?.general_service?.id} className="flex flex-col gap-1">
                  <p className="text-sm-medium text-gray-700">{service?.general_service?.name}</p>
                  <p className="text-xs-regular text-gray-500">{service?.sparepart?.name}</p>
                </li>
              ))}
            </ol>
          </div>
          <div className="flex justify-end items-center gap-2">
            <Spin spinning={postService?.isLoading}>
              <CustomButton type="plain" label={'Batal'} onClick={handleCloseModal} />
            </Spin>
            <Spin spinning={postService?.isLoading}>
              <CustomButton label={'Konfirmasi'} onClick={handleCheckout} />
            </Spin>
          </div>
        </div>
      </ModalLayout>

      {/* MODAL VOUCHER LIST */}
      <ModalLayout
        openModal={modalVoucherOpen}
        headerTitle="Apply Voucher"
        className="w-full max-w-lg max-h-fit"
        onCancel={() => {
          handleModalVoucherClose()
          resetTemporarySelectedVouchers()
        }}>
        <div className="w-full max-w-lg grid grid-cols-1 gap-2">
          <p className="text-sm-medium text-gray-700">Kode Voucher</p>
          {/* INPUT VOUCHER CODE */}
          <div className="grid grid-cols-[1fr_75px] gap-2">
            <Input
              name="voucher_code"
              type="text"
              placeholder="Masukan Kode Voucher"
              value={temporaryVoucherCode}
              onChange={(event) => setTemporaryVoucherCode(event.target.value)}
            />
            <CustomButton
              disabled={temporaryVoucherCode.length === 0}
              label={'Pakai'}
              onClick={() => handleVoucherCode()}
            />
          </div>
          {/* LIST CODE VOUCHER APPLIED */}
          <div className="grid grid-cols-1 gap-2">
            {temporarySelectedVoucher
              ?.filter((voucher) => voucher?.is_private)
              .map((voucherPrivate) => (
                <div
                  key={voucherPrivate?.id}
                  className={`p-4 grid grid-cols-[1fr_30px] gap-2 border border-solid rounded border-primary-300 bg-primary-50 cursor-pointer`}>
                  {/* Col 1 - detail Voucher */}
                  <div className="grid grid-cols-1 gap-2">
                    <span className={`text-md-medium text-primary-700`}>{voucherPrivate.name}</span>
                    <span className={`text-sm-regular text-primary-600`}>
                      {/* discount_for 1 for "layanan", 2 for "sparepart", 3 for "sparepart & layanan" */}
                      {/* discount_type 1 for rupiah, 2 for percent */}
                      Diskon{' '}
                      {voucherPrivate?.discount_for === 1
                        ? 'layanan'
                        : voucherPrivate?.discount_for === 2
                        ? 'sparepart'
                        : voucherPrivate?.discount_for === 3
                        ? 'sparepart & layanan'
                        : voucherPrivate?.discount_for === 4
                        ? 'Tertentu'
                        : ''}{' '}
                      {voucherPrivate?.discount_type === 1
                        ? `Rp ${onRupiah(voucherPrivate?.discount_integer)}`
                        : voucherPrivate?.discount_type === 2
                        ? `${voucherPrivate?.discount_integer}%`
                        : ``}
                    </span>
                  </div>
                  {/* X icon */}
                  <div
                    className="w-[20px] cursor-pointer text-center justify-center items-center flex"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleTemporarySelectedVoucher(voucherPrivate)
                    }}>
                    <XCircleIcon className="stroke-primary-400 w-5 h-5" />
                  </div>
                </div>
              ))}
          </div>
          <Divider className="my-2 mx-0" />
          {/* List Voucher */}
          {/* <p className="text-sm-medium text-gray-700">List Voucher</p> */}
          {/* Card Voucher */}
          <div className="max-h-52 flex flex-col gap-2 overflow-y-scroll h-full">
            {voucherList.length > 0 &&
              voucherList.map((data) => {
                const isVoucherValid = checkIsVoucherValid(data?.is_available, data?.discount_for)
                if (data?.is_private) {
                  return
                }
                return (
                  <div
                    key={data.id}
                    className={`p-4 grid grid-cols-1 gap-1 border border-solid rounded ${
                      temporarySelectedVoucherIds?.[data?.id]
                        ? 'border-primary-300 bg-primary-50 cursor-pointer'
                        : isVoucherValid
                        ? 'border-gray-200 bg-white cursor-pointer'
                        : 'border-gray-200 bg-gray-50'
                    }`}
                    onClick={() => {
                      isVoucherValid
                        ? handleTemporarySelectedVoucher(data)
                        : temporarySelectedVoucherIds?.[data?.id]
                        ? handleTemporarySelectedVoucher(data)
                        : null
                    }}>
                    <div className="grid grid-cols-[30px_1fr] gap-2">
                      <div className="flex items-center justify-center">
                        <input
                          className="w-4 h-4 rounded border border-solid border-gray-200  accent-primary-600"
                          type="checkbox"
                          id={data?.id}
                          disabled={!temporarySelectedVoucherIds?.[data?.id] && !isVoucherValid}
                          onChange={() => {}}
                          checked={temporarySelectedVoucherIds?.[data?.id] ? true : false}
                        />
                      </div>
                      {/* Title */}
                      <div className="grid grid-cols-1 gap-2">
                        <div className="flex justify-between">
                          <span
                            className={`text-md-medium ${
                              temporarySelectedVoucherIds?.[data?.id]
                                ? 'text-primary-700'
                                : isVoucherValid
                                ? 'text-gray-900'
                                : 'text-gray-300'
                            }`}>
                            {data.name}
                          </span>
                          <span
                            className={`text-xs-regular ${
                              temporarySelectedVoucherIds?.[data?.id]
                                ? 'text-primary-700'
                                : isVoucherValid
                                ? 'text-gray-600'
                                : 'text-gray-300'
                            }`}>
                            Berakhir {moment(data?.expiry_date).format('DD MMM YYYY')}
                          </span>
                        </div>
                        {/* Expired & Detail Button */}
                        <div className="flex gap-2 justify-between">
                          <p
                            className={`text-sm-regular ${
                              temporarySelectedVoucherIds?.[data?.id]
                                ? 'text-primary-600'
                                : isVoucherValid
                                ? 'text-gray-600'
                                : 'text-gray-300'
                            }`}>
                            {/* discount_for 1 for "layanan", 2 for "sparepart", 3 for "sparepart & layanan" */}
                            {/* discount_type 1 for rupiah, 2 for percent */}
                            Diskon{' '}
                            {data?.discount_for === 1
                              ? 'layanan'
                              : data?.discount_for === 2
                              ? 'sparepart'
                              : data?.discount_for === 3
                              ? 'sparepart & layanan'
                              : data?.discount_for === 4
                              ? 'tertentu'
                              : ''}{' '}
                            {data?.discount_type === 1
                              ? `Rp ${onRupiah(data?.discount_integer)}`
                              : data?.discount_type === 2
                              ? `${data?.discount_integer}%`
                              : ``}
                          </p>
                          <p
                            onClick={(e) => {
                              e.stopPropagation()
                              handleSelectedDetailCoupon(data)
                              handleModalDetailCoupon()
                            }}
                            className="text-sm-semibold cursor-pointer text-primary-600">
                            Detail
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="flex gap-2 justify-end items-center">
            <CustomButton
              type="plain"
              label={'Batal'}
              onClick={() => {
                handleModalVoucherClose()
                resetTemporarySelectedVouchers()
              }}
            />
            <CustomButton label={'Pakai Voucher'} onClick={() => applySelectedVouchers()} />
          </div>
        </div>
      </ModalLayout>

      {/* MODAL DETAIL COUPON */}
      <ModalLayout
        openModal={modalDetailCoupon}
        headerTitle="Detail Voucher"
        className="w-full max-w-lg"
        onCancel={handleModalDetailCoupon}>
        {selectedDetailCoupon ? (
          <div className="max-w-lg grid grid-cols-1 gap-2">
            {/* Header */}
            <div className="flex flex-col gap-2">
              <p className="text-lg-medium md:text-xl-medium text-black">
                {selectedDetailCoupon?.name}
              </p>
              <div className="flex justify-between items-center ">
                <p className="text-sm-regular md:text-md-regular text-gray-600">Berlaku Hingga</p>
                <p className="text-sm-medium md:text-md-medium text-gray-700">
                  {moment(selectedDetailCoupon?.expiry_date).format('DD MMMM YYYY')}
                  {/* <Countdown date={new Date(selectedDetailCoupon?.expiry_date)} /> */}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm-regular md:text-md-regular text-gray-600">Transaksi</p>
                <p className="text-sm-medium md:text-md-medium text-gray-700">
                  {selectedDetailCoupon?.discount_for === 1
                    ? 'Layanan'
                    : selectedDetailCoupon?.discount_for === 2
                    ? 'Sparepart'
                    : selectedDetailCoupon?.discount_for === 3
                    ? 'Sparepart & Layanan'
                    : selectedDetailCoupon?.discount_for === 4
                    ? 'Tertentu'
                    : ''}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm-regular md:text-md-regular text-gray-600">
                  Minimum Transaksi
                </p>
                <p className="text-sm-medium md:text-md-medium text-gray-700">
                  Rp {onRupiah(selectedDetailCoupon?.min_transaction)}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm-regular md:text-md-regular text-gray-600">Potongan</p>
                <p className="text-sm-medium md:text-md-medium text-gray-700">
                  {selectedDetailCoupon?.discount_type === 1
                    ? `Rp ${onRupiah(selectedDetailCoupon?.discount)}`
                    : `${selectedDetailCoupon?.discount}%`}
                </p>
              </div>
              {selectedDetailCoupon?.max_discount ? (
                <div className="flex justify-between items-center">
                  <p className="text-sm-regular md:text-md-regular text-gray-600">
                    Potongan Maksimal
                  </p>
                  <p className="text-sm-medium md:text-md-medium text-gray-700">
                    Rp {onRupiah(selectedDetailCoupon?.max_discount)}
                  </p>
                </div>
              ) : null}
            </div>
            {selectedDetailCoupon?.discount_for === 4 ? (
              <div className="flex flex-col gap-3">
                <p className="text-sm-regular md:text-md-regular text-gray-600">
                  Voucher digunakan untuk :{' '}
                </p>
                {selectedDetailCoupon?.spareparts?.length > 0 ? (
                  <div className="flex flex-col gap-2">
                    <p className="text-sm-regular md:text-md-regular text-gray-600">Sparepart</p>
                    <div className="flex flex-wrap whitespace-nowrap gap-2">
                      {selectedDetailCoupon?.spareparts?.map((service) => (
                        <BadgeItem key={`badge-service-${service.id}`} type="plain">
                          {service?.name}
                        </BadgeItem>
                      ))}
                    </div>
                  </div>
                ) : null}
                {selectedDetailCoupon?.general_services?.length > 0 ? (
                  <div className="flex flex-col gap-2">
                    <p className="text-sm-regular md:text-md-regular text-gray-600">Layanan</p>
                    <div className="flex flex-wrap whitespace-nowrap gap-2">
                      {selectedDetailCoupon?.general_services?.map((service) => (
                        <BadgeItem key={`badge-service-${service.id}`} type="plain">
                          {service?.name}
                        </BadgeItem>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {selectedDetailCoupon?.description ? (
              <>
                <Divider className="my-2 mx-0" />
                <div className="flex flex-col ">
                  <p className="text-lg-medium md:text-xl-medium text-black">Deskripsi Voucher</p>
                  <div
                    className="ql-editor !px-0"
                    dangerouslySetInnerHTML={{ __html: selectedDetailCoupon?.description }}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </ModalLayout>
    </Row>
  )
}

export default FormServicePage
