import { Divider, Input, InputNumber } from 'antd'
import { MagnifyingIcon } from 'assets'
import PlusIcon from 'assets/icons/plus'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import React, { useState } from 'react'
import { onRupiah } from 'utils/formatCurrency'
import { toHoursAndMinutes } from 'utils/toHoursAndMinutes'
import PartList from '../part-list/part-list'
import { showSuccessMessage, showWarningMessage } from 'utils/toastMessage'

const CardService = ({ data, handleGeneralService }) => {
  let lowestPrice = Infinity
  data?.spareparts.map((sparepart) => {
    if (sparepart.price < lowestPrice) lowestPrice = sparepart.price
  })
  const [keyword, setKeyword] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [selected, setSelected] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleSelected = (obj) => {
    setSelected(obj)
  }
  function handleSearchKeyword(e) {
    const { value } = e.target
    setKeyword(value)
    if (value === '') {
      setFilteredData({
        ...filteredData,
        spareparts: data?.spareparts
      })
    } else {
      // HANDLE ARRAY SPAREPARTS DEPENDS ON KEYWORD
      const newSpareparts = data?.spareparts?.filter((sparepart) =>
        sparepart?.name.toLowerCase().includes(value)
      )
      setFilteredData({ ...filteredData, spareparts: newSpareparts })
    }
  }
  function handleAddToCartGeneralService() {
    // console.log('filteredData', filteredData)
    if (filteredData?.is_free || selected) {
      const newServiceGenerals = {
        general_service: {
          id: filteredData.id,
          name: filteredData.name
        },
        totalDuration: filteredData.duration,
        totalBiayaLayanan: filteredData.price,
        sparepart: selected ? selected : { id: 0 },
        is_free: filteredData?.is_free
      }
      // Parent Func Handle General Service
      handleGeneralService(newServiceGenerals)
      showSuccessMessage('Paket berhasil ditambahkan')
      handleCloseModal()
    } else {
      showWarningMessage('Silahkan pilih sparepart terlebih dahulu')
    }
  }
  return (
    <>
      <CardSectionHeader heightMax>
        <div className="grid grid-cols-1 gap-6">
          {/* title & Recommended Tag */}
          <div className="relative">
            {/* Title */}
            <p className="text-lg-semibold text-gray-900">{data?.name}</p>
          </div>
          {/* Estimate & Recommendation Speedometer */}
          <div className="flex gap-6">
            <div className="flex flex-col">
              <p className="text-xs-medium text-gray-500">Estimasi</p>
              <p className="text-sm-medium text-gray-600">{toHoursAndMinutes(data?.duration)}</p>
            </div>
          </div>
          {/* Price & AddToCard Button */}
          <div className="flex md:flex-row flex-col gap-2 md:justify-between md:items-center">
            <div className="flex flex-col gap-1">
              <p className="text-sm-medium text-gray-500">Mulai dari</p>
              <p className="text-warning-500 text-lg-semibold">Rp {onRupiah(data?.price_total)}</p>
            </div>
            <CustomButton
              onClick={handleOpenModal}
              type="secondary"
              label={
                <div className="flex items-center gap-3">
                  <PlusIcon className={'stroke-primary-600'} />
                  Tambah ke Keranjang
                </div>
              }
            />
          </div>
        </div>
      </CardSectionHeader>
      {/* Modal Opsi */}
      <ModalLayout
        className={'w-full max-w-screen-sm '}
        headerTitle={'Pilih Opsi'}
        openModal={openModal}
        onCancel={handleCloseModal}>
        <div className="max-h-[440px] overflow-hidden overflow-y-scroll p-2 flex flex-col gap-4 service-modal">
          <div className="max-h-[440px] grid grid-cols-1 gap-4">
            <p className="text-lg-medium text-gray-700">{filteredData?.name}</p>
            <Input
              onChange={(e) => handleSearchKeyword(e)}
              value={keyword}
              name={filteredData?.name}
              size="medium"
              className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
              prefix={<MagnifyingIcon className={'mr-1'} />}
              placeholder="Cari Produk"
            />
            {/* TODO: MAP */}
            <PartList
              handleSelected={handleSelected}
              spareparts={filteredData?.spareparts}
              selected={selected?.id}
            />
            <Divider className="m-0" />
          </div>
        </div>
        <Divider className="mt-0" />
        <div className="flex flex-col gap-2">
          <p className="text-sm-medium text-gray-500">Rincian Item</p>
          {selected !== null && (
            <div className="flex justify-between items-center text-gray-700 text-sm-medium">
              <p>{selected?.name}</p>
              <div className="flex gap-2 items-center">
                <span>QTY: </span>
                <InputNumber min={1} disabled value={selected?.quantity} />
              </div>
            </div>
          )}
        </div>
        <Divider className="mt-2" />
        <div className="flex justify-end items-center gap-3 mt-2">
          <CustomButton label={'Batal'} type="plain" onClick={handleCloseModal} />
          <CustomButton label={'Tambah ke Keranjang'} onClick={handleAddToCartGeneralService} />
        </div>
      </ModalLayout>
    </>
  )
}

export default CardService
