import { Button, Select } from 'antd'
import DataTable from 'react-data-table-component'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

function CustomTable({
  columns,
  data,
  currentPage,
  totalPerPage,
  lengthAllData,
  dropDownDataFilter,
  defaultPageSize,
  onChangeNextPage,
  onChangePrevPage,
  onChangePageSize,
  hidePagination = false
}) {
  const customStyleText = {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054'
  }
  const customStyles = {
    rows: {
      style: {
        '&:not(:last-of-type)': {
          borderBottomStyle: 'none'
        },
        padding: '16px 0',
        ...customStyleText
      }
    },
    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        ...customStyleText
      }
    }
  }
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        className="border border-solid border-gray-200 rounded-[4px] text-sm-medium"
      />

      {/* pagination */}
      {hidePagination ? null : (
        <div className="flex w-full flex-row items-center justify-between md:justify-end space-x-4 mt-6">
          <div className={`${dropDownDataFilter ? 'flex flex-row items-center' : ''} mr-4`}>
            <label className="text-gray-700 text-sm-regular mr-2 md:mr-4">Tampilkan</label>
            <Select
              className="custom-table-limit md:w-70px"
              // style={{ width: '70px' }}
              defaultValue="5"
              value={defaultPageSize}
              onChange={(value) => onChangePageSize('limit', value)}>
              <Select.Option className="custom-table-limit-option" value="5">
                5
              </Select.Option>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="25">25</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <div className="flex items-center space-x-3">
            <div className="flex flex-row">
              <span className="text-md-mdeium text-gray-700">
                {`${currentPage ?? '1'} - ${totalPerPage ?? data?.length} dari ${
                  lengthAllData ?? data?.length
                }`}
              </span>
            </div>
            <div className="flex flex-row space-x-3">
              <Button
                className="group p-0 flex border border-solid border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
                onClick={onChangePrevPage ? () => onChangePrevPage() : () => {}}>
                <AiOutlineLeft className="hover:border-primary-500" />
              </Button>
              <Button
                className="group p-0 flex border border-solid border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
                onClick={onChangeNextPage ? () => onChangeNextPage() : () => {}}>
                <AiOutlineRight className="hover:border-primary-500" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomTable
