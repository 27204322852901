import { Col, Row } from 'antd'
import CalendarIcon from 'assets/icons/calendar'
import { CloseOutlined } from '@ant-design/icons'
import {
  CardB2BEmail,
  CardChangePassword,
  CardDebitCreditList,
  CardDeleteAccount,
  CardInfoB2B,
  CardProfileInformation
} from 'features/profile'
import { ModalLayout } from 'layouts'
import { ModalChangePassword, ModalEditProfile } from 'features/profile/modals'
import ConfirmationModal from 'components/ui/confirmation-modal'
import { NavLink, useHistory } from 'react-router-dom'
import { useChangePassword, useProfile } from 'features/profile/hooks'
import ModalAddCreditCard from 'components/ui/modal/modal-add-credit-card'
import { useCreditCard } from 'hooks'
import ModalDetailCard from 'components/ui/modal/modal-detail-card'
import useWindowSize from 'lib/useWindowSize'

function ProfilePage() {
  const { replace } = useHistory()
  const token = localStorage.getItem('token')
  const isB2B = localStorage.getItem('account_type')
  const userStorage = JSON.parse(localStorage.getItem('user'))
  if (token === null || token === undefined) {
    replace('/')
  }
  const {
    modalEditProfile,
    modalDeleteAccount,
    state,
    discountState,
    cities,
    fullData,
    errorsState,
    provinces,
    subdistricts,
    handleChange,
    handleChangeLocation,
    handleDeleteAccount,
    openModalDeleteAccount,
    openModalEditAccount,
    closeModalDeleteAccount,
    closeModalEditAccount,
    handleSubmitEdit
  } = useProfile(isB2B, token)
  const {
    modalChangePassword,
    openModalChangePassword,
    closeModalChangePassword,
    passwords,
    errorPasswords,
    isOTPSuccess,
    modalConfirmChangePassword,
    openModalConfirmChangePassword,
    closeModalConfirmChangePassword,
    handleChangePassword,
    OTPFail,
    handleResend,
    handleSubmitOTP,
    handleSubmitPassword,
    resetStateChangePassword
  } = useChangePassword(fullData)
  const {
    modalPreviewCard,
    creditCards,
    newCreditCard,
    selectedCreditCard,
    modalAddCard,
    isAddCardLoading,
    errorsAddCard,
    handlePreviewSelectedCard,
    handleChangeCreditCard,
    submitAddCard,
    openModalAddCard,
    closeModalAddCard,
    handleDeleteCard,
    closeModalPreviewCard,
    modalConfirmDeleteCard,
    handleConfirmDeleteCard,
    closeModalConfirmDeleteCard
  } = useCreditCard(false)
  const [width] = useWindowSize()

  return (
    <>
      <Row className="gap-6">
        {/* Header */}
        <Col span={24} className="text-xl-medium text-gray-900">
          Profil
        </Col>
        {/* Reminder */}
        {userStorage?.address !== '' &&
        userStorage?.postal_code !== '' &&
        userStorage?.province_name !== '' &&
        userStorage?.city_name !== '' &&
        userStorage?.sub_district_name !== '' &&
        userStorage?.phone_number !== '' ? null : (
          <Col
            span={24}
            className="py-3 px-3 md:px-6 bg-warning-50 border border-solid border-warning-300 flex justify-between cursor-pointer">
            {/* Title & Description */}
            <div className="text-warning-700 text-sm-medium lg:text-md-medium stroke-warning-700 flex flex-col gap-2">
              <div className="flex gap-2 md:gap-7 items-center">
                <CalendarIcon className={'w-5 h-4'} />
                <p>Lengkapi profil Anda agar dapat melakukan transaksi</p>
              </div>
            </div>
            {/* Lihat Detail */}
            <div className="flex gap-2 stroke-warning-700 text-warning-700 items-center justify-center">
              <CloseOutlined />
            </div>
          </Col>
        )}
        {/* Content */}
        <Col span={24} className="grid grid-cols-1 gap-6">
          {/* Information */}
          <CardProfileInformation
            isB2B={isB2B !== 'customer'}
            data={fullData}
            handleClick={openModalEditAccount}
          />
          {isB2B !== 'customer' && <CardB2BEmail />}
          {/* info potongan harga & jatuh tempo */}
          {isB2B !== 'customer' && <CardInfoB2B data={discountState} />}
          {/* Ubah password */}
          <CardChangePassword handleClick={openModalConfirmChangePassword} />
          <CardDebitCreditList
            width={width}
            handleClick={openModalAddCard}
            creditCards={creditCards}
            handlePreviewSelectedCard={handlePreviewSelectedCard}
            openModalAddCard={openModalAddCard}
            handleConfirmDeleteCard={handleConfirmDeleteCard}
          />
          {/* Hapus Akun */}
          <CardDeleteAccount isB2B={isB2B !== 'customer'} handleClick={openModalDeleteAccount} />
        </Col>
      </Row>
      {/* PROFILE */}
      <ModalLayout
        headerTitle={'Edit Profil'}
        onCancel={closeModalEditAccount}
        openModal={modalEditProfile}
        className="max-w-[500px] w-full">
        <ModalEditProfile
          isB2B={isB2B !== 'customer'}
          state={state}
          errors={errorsState}
          cities={cities}
          provinces={provinces}
          subdistricts={subdistricts}
          handleChange={handleChange}
          handleChangeLocation={handleChangeLocation}
          onCancel={closeModalEditAccount}
          onClick={handleSubmitEdit}
        />
      </ModalLayout>
      <ConfirmationModal
        className="w-full max-w-[400px]"
        headerTitle={'Konfirmasi Hapus Akun'}
        openModal={modalDeleteAccount}
        confirmLabel={'Hapus'}
        onCancel={closeModalDeleteAccount}
        onClick={handleDeleteAccount}
        contentDescription={
          'Yakin untuk menghapus akun? Akun yang Anda hapus tidak dapat dipulihkan kembali.'
        }
      />
      {/* END PROFILE */}
      {/* CHANGE PASSWORD */}
      <ConfirmationModal
        className="w-full max-w-[400px]"
        headerTitle={'Konfirmasi Ganti Password'}
        openModal={modalConfirmChangePassword}
        confirmLabel={'Konfirmasi'}
        onCancel={closeModalConfirmChangePassword}
        onClick={openModalChangePassword}
        contentDescription={
          'Yakin untuk lanjut mengubah password Anda? Tekan Konfirmasi untuk lanjut ganti password.'
        }
      />
      <ModalLayout
        headerTitle={'Ubah Password'}
        onCancel={closeModalChangePassword}
        openModal={modalChangePassword}
        className="max-w-[500px] w-full">
        <ModalChangePassword
          isOTPSuccess={isOTPSuccess}
          passwords={passwords}
          errors={errorPasswords}
          handleResend={handleResend}
          handleSubmitPassword={handleSubmitPassword}
          handleChangePassword={handleChangePassword}
          onCancel={resetStateChangePassword}
          handleSubmitOTP={handleSubmitOTP}
        />
      </ModalLayout>
      {/* END CHANGE PASSWORD */}
      {/* CREDIT CARDS */}
      <ModalAddCreditCard
        errors={errorsAddCard}
        creditCard={newCreditCard}
        openModal={modalAddCard}
        submitAddCard={submitAddCard}
        isAddCardLoading={isAddCardLoading}
        onCancel={closeModalAddCard}
        handleChange={handleChangeCreditCard}
      />
      <ModalDetailCard
        card={selectedCreditCard}
        openModal={modalPreviewCard}
        onCancel={closeModalPreviewCard}
        handleConfirmDeleteCard={handleConfirmDeleteCard}
        width={width}
      />
      <ConfirmationModal
        className="w-full max-w-[400px]"
        headerTitle={'Konfirmasi Hapus Kartu'}
        openModal={modalConfirmDeleteCard}
        confirmLabel={'Konfirmasi'}
        onCancel={closeModalConfirmDeleteCard}
        onClick={() => {
          handleDeleteCard(selectedCreditCard)
        }}
        contentDescription={
          <>
            <span>
              Yakin untuk lanjut menghapus kartu Anda? <br />
            </span>
            <span>Tekan Konfirmasi untuk lanjut hapus kartu.</span>
          </>
        }
      />
      {/* END CREDIT CARDS */}
    </>
  )
}

export default ProfilePage
