import { Divider, Input, InputNumber } from 'antd'
import { ChecklistIcon, MagnifyingIcon } from 'assets'
import PlusIcon from 'assets/icons/plus'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import React, { useState } from 'react'
import { onRupiah } from 'utils/formatCurrency'
import { toHoursAndMinutes } from 'utils/toHoursAndMinutes'
import PartList from '../part-list/part-list'
import { showSuccessMessage, showWarningMessage } from 'utils/toastMessage'

const CardPacketService = ({ data, handlePacketService }) => {
  const general_services = []
  const newObjKeyword = {}
  let duration = 0
  const newObjIds = {}
  // MAPPING TO GET TOTAL DURATION,GENERAL_SERVICE NAME, LOWEST PRICE
  data?.general_services.map((generalService) => {
    // MASIH PAKE
    duration += generalService.duration
    if (generalService.is_free === true) {
      newObjIds[generalService.id] = {
        id: 0
      }
    } else if (generalService.is_free === false) {
      newObjIds[generalService.id] = null
    }
    general_services.push(generalService.name)
  })
  // Dynamic search each service
  general_services.forEach((service) => {
    newObjKeyword[service] = null
  })
  const [expand, setExpand] = useState(false)
  const [keyword, setKeyword] = useState(newObjKeyword)
  const [selected, setSelected] = useState(newObjIds)

  const [filteredData, setFilteredData] = useState({ ...data })
  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  function handleSelected(key, obj) {
    setSelected({ ...selected, [key]: obj })
    console.log(key, obj)
  }
  function handleSearchKeyword(e, id) {
    const { name, value } = e.target
    setKeyword({ ...keyword, [name]: value })
    let index = filteredData.general_services.findIndex((service) => service.id === id)
    if (value === '') {
      setFilteredData({
        ...filteredData,
        general_services: data?.general_services
      })
    } else {
      // HANDLE LIST OF SPAREPARTS DEPENDS ON KEYWORD
      const newGeneralService = [...data.general_services]
      const newSpareparts = data?.general_services[index]?.spareparts?.filter((sparepart) =>
        sparepart?.name.toLowerCase().includes(value)
      )
      newGeneralService[index] = {
        ...newGeneralService[index],
        spareparts: newSpareparts
      }
      setFilteredData({ ...filteredData, general_services: newGeneralService })
    }
  }
  function handleTambahKeranjang() {
    let valid = true
    for (const [key] of Object.entries(selected)) {
      if (selected[key] === null) {
        valid = false
        break
      }
    }
    if (valid) {
      // CREATE ARRAY OF OBJECT FOR CART ITEMS & POST SERVICE
      let newArray = []
      let totalDuration = 0
      data?.general_services.map((service) => {
        totalDuration += service.duration
        let newObj = {
          general_service: { id: service.id, name: service.name, is_free: service.is_free },
          sparepart: selected[service.id]
        }
        newArray.push(newObj)
      })
      handlePacketService(newArray, data.name, totalDuration)
      showSuccessMessage('Paket berhasil ditambahkan')
      handleCloseModal()
    } else {
      showWarningMessage('Pilih sparepart terlebih dahulu')
    }
  }
  const slicedData = expand ? general_services : general_services.slice(0, 5)
  return (
    <>
      <CardSectionHeader heightMax>
        <div className="grid grid-cols-1 gap-6">
          {/* title & Recommended Tag */}
          <div>
            {/* Title */}
            <p className="text-lg-semibold text-gray-900">{data?.name}</p>
          </div>
          {/* Estimate & Recommendation Speedometer */}
          <div className="flex gap-6">
            <div className="flex flex-col">
              <p className="text-xs-medium text-gray-500">Estimasi</p>
              <p className="text-sm-medium text-gray-600">{toHoursAndMinutes(duration)}</p>
            </div>
          </div>
          {/* Service List Detail */}
          <div className="grid grid-cols-2 gap-2 content-between">
            {slicedData.map((data, index) => (
              <p
                key={index}
                className="text-gray-600 text-sm-medium grid grid-cols-[12px_1fr] items-center gap-2">
                <ChecklistIcon className={'h-3 w-3'} /> {data}
              </p>
            ))}
            {general_services.length > 5 ? (
              <div className="flex items-center">
                <p
                  onClick={() => {
                    setExpand((prevValue) => !prevValue)
                  }}
                  className={`cursor-pointer text-sm-medium ${
                    expand ? 'text-gray-500' : 'text-primary-600'
                  }`}>
                  {expand ? 'Lebih Sedikit' : `+${general_services.length - 5} more view all`}
                </p>
              </div>
            ) : null}
          </div>
          {/* Price & AddToCard Button */}
          <div className="flex md:flex-row flex-col gap-2 md:justify-between md:items-center">
            <div className="flex flex-col gap-1">
              <p className="text-sm-medium text-gray-500">Mulai dari</p>
              <p className="text-warning-500 text-lg-semibold">Rp {onRupiah(data?.price_total)}</p>
            </div>
            <CustomButton
              onClick={handleOpenModal}
              type="secondary"
              label={
                <div className="flex items-center gap-3">
                  <PlusIcon className={'stroke-primary-600'} />
                  Tambah ke Keranjang
                </div>
              }
            />
          </div>
        </div>
      </CardSectionHeader>
      {/* Modal Opsi */}
      <ModalLayout
        className={'w-full max-w-screen-sm '}
        headerTitle={'Pilih Opsi'}
        openModal={openModal}
        onCancel={handleCloseModal}>
        <div className="max-h-[440px] overflow-hidden overflow-y-scroll p-2 flex flex-col gap-4 service-modal">
          {filteredData?.general_services?.map((service) => (
            <div key={service?.id} className="max-h-[440px] grid grid-cols-1 gap-4">
              <p className="text-lg-medium text-gray-700">{service?.name}</p>
              {service.is_free === true ? null : (
                <>
                  <Input
                    onChange={(e) => handleSearchKeyword(e, service?.id)}
                    value={keyword[service?.name]}
                    name={service?.name}
                    size="medium"
                    className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
                    prefix={<MagnifyingIcon className={'mr-1'} />}
                    placeholder="Cari Produk"
                  />
                  <PartList
                    spareparts={service?.spareparts}
                    handleSelected={handleSelected.bind(this, service?.id)}
                    selected={selected[service?.id]?.id}
                  />
                </>
              )}
              <Divider className="m-0" />
            </div>
          ))}
        </div>
        <Divider className="mt-0" />
        <div className="flex flex-col gap-2">
          <p className="text-sm-medium text-gray-500">Rincian Item</p>
          {Object.keys(selected).map(
            (key, index) =>
              selected[key] !== null &&
              selected[key].id !== 0 && (
                <div
                  className="flex justify-between items-center text-gray-700 text-sm-medium"
                  key={index}>
                  <p>{selected[key]?.name}</p>
                  <div className="flex gap-2 items-center">
                    <span>QTY: </span>
                    <InputNumber min={1} disabled value={selected[key]?.quantity} />
                  </div>
                </div>
              )
          )}
        </div>
        <Divider className="mt-2" />
        <div className="flex justify-end items-center gap-3 mt-2">
          <CustomButton label={'Batal'} type="plain" onClick={handleCloseModal} />
          <CustomButton label={'Tambah ke Keranjang'} onClick={handleTambahKeranjang} />
        </div>
      </ModalLayout>
    </>
  )
}

export default CardPacketService
