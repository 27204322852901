import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getCities, getProvinces, getRangePrice, getSubdistricts, getWorkshops } from '../service'
import { useScheduleService } from 'hooks'
import { nameToString } from 'utils/nameToString'

const useJadwalService = (setDataUser, currentContent) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const [homeState, setHomeState] = useState({
    province: {
      label: user?.province_name || null,
      value: user?.province_id || null
    },
    city: {
      label: user?.city_name || null,
      value: user?.city_id || null
    },
    subdistrict: {
      label: user?.sub_district_name || null,
      value: user?.sub_district_id || null
    },
    postal_code: user?.postal_code || '',
    address: user?.address || '',
    range_price: {},
    loc: {}
  })
  const [isShowRoom, setIsShowRoom] = useState(true)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const [workshops, setWorkshops] = useState([])
  const [rangePrice, setRangePrice] = useState([])
  const { times, schedule, handleChangeSchedule, scheduleIsValid, resetSchedule } =
    useScheduleService('servis')
  useQuery({
    queryKey: 'getWorkshops',
    queryFn: () => getWorkshops(),
    onSuccess: (data) => {
      setWorkshops(data)
      setDataUser((prev) => ({ ...prev, workshop: data[0] }))
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getRangePrice',
    queryFn: () => getRangePrice(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: `${el.start_distance} km - ${el.end_distance} km`,
        value: el.id,
        price: el.price
      }))
      setRangePrice(newData)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: currentContent === 2
  })
  useQuery({
    queryKey: ['getCities', homeState?.province?.value],
    queryFn: () => getCities(homeState?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities((prev) => [...prev, ...newData])
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: homeState?.province?.value !== undefined
  })
  useQuery({
    queryKey: ['getSubdistricts', homeState?.city?.value],
    queryFn: () => getSubdistricts(homeState?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts((prev) => [...prev, ...newData])
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: homeState?.city?.value !== undefined
  })

  const handleChangeHomeData = (name, value) => {
    setHomeState((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeLocation = (name, value) => {
    let newValue = {}
    if (name === 'province') {
      newValue = {
        ...homeState,
        province: value,
        city: {},
        subdistrict: {}
      }
      setCities([])
      setSubdistricts([])
    } else if (name === 'city') {
      newValue = {
        ...homeState,
        city: value,
        subdistrict: {}
      }
      setSubdistricts([])
    } else {
      newValue = {
        ...homeState,
        subdistrict: value
      }
    }

    setHomeState(newValue)
  }

  const handleChangeActiveWorkshop = (value) => {
    setDataUser((prev) => ({
      ...prev,
      workshop: value
    }))
  }

  function handleValidationJadwalService(selectedWorkshop) {
    if (!isShowRoom) {
      let valid = true
      let nameError = ''
      for (const [key] of Object.entries(homeState)) {
        if (key === 'loc') continue
        if (
          homeState[key] == '' ||
          (typeof homeState[key] === 'object' && homeState[key].value == null)
        ) {
          valid = false
          nameError = String(key)
          break
        }
      }
      if (!valid) {
        // console.log(nameError)
        return {
          status: false,
          errorMessage: `Mohon isi ${nameToString[nameError]}`
        }
      }
    }
    // if (schedule.date === null || schedule.hours === null || selectedWorkshop === null) {
    //   return { status: false, errorMessage: 'Mohon pilih jadwal dan bengkel terlebih dahulu' }
    // }
    if (schedule.date === null) {
      return { status: false, errorMessage: 'Mohon isi Tanggal Servis' }
    } else if (schedule.hours === null) {
      return { status: false, errorMessage: 'Mohon isi Jam Servis' }
    } else if (selectedWorkshop === null) {
      return { status: false, errorMessage: 'Mohon Pilih Bengkel Servis' }
    }

    return { status: true, errorMessage: '' }
  }

  useEffect(() => {
    // setHomeState({ ...initialHomeState })
    resetSchedule()
  }, [isShowRoom])
  return {
    isShowRoom,
    setIsShowRoom,
    homeState,
    schedule,
    times,
    provinces,
    cities,
    subdistricts,
    workshops,
    rangePrice,
    handleChangeActiveWorkshop,
    handleChangeHomeData,
    handleChangeLocation,
    handleChangeSchedule,
    handleValidationJadwalService,
    scheduleIsValid
  }
}

export default useJadwalService
