import { DownloadIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import moment from 'moment'

function CardInvoice({ data, openModal, status }) {
  const renderButton = {
    // 'scheduled': true,
    // 'done_scheduled': true,
    // 'ongoing': true,
    done: true,

    booked: true,
    'booking lunas': true,
    selesai: true,

    failed: true
  }

  return (
    <CardSectionHeader headerTitle="Invoice">
      <div className="grid grid-col-1 gap-5 ">
        <p className="text-primary-500 text-lg-medium">{data?.catalog_invoice?.invoice_number}</p>
        <p className="text-sm-medium text-gray-600 ">
          {moment(data?.catalog_invoice?.created_at).format('DD MMMM YYYY - HH:mm')} WIB
        </p>
        {renderButton[status] ||
        ((status === 'scheduled' || status === 'ongoing' || status === 'done_scheduled') &&
          data.serviceType !== 'servis') ? (
          <CustomButton
            type="plain"
            onClick={openModal}
            label={
              <div className="flex items-center gap-3 justify-center stroke-gray-700">
                <DownloadIcon className="h-5 w-5" /> Download Invoice
              </div>
            }
          />
        ) : null}
      </div>
    </CardSectionHeader>
  )
}

export default CardInvoice
