import { Button, Typography } from 'antd'
import { IcEdit, IcTrash } from 'assets'
import PlusIcon from 'assets/icons/plus'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import CustomTable from 'components/ui/custom-table'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { deleteEmailB2B, getEmailListB2B, postEmailB2B, putEmailB2B } from '../service'
import { useState } from 'react'
import { ADD, DELETE, EDIT } from 'data/constants'
import ModalCreateEditEmail from '../modals/B2BEmail/modal-create-edit-email'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
import ModalDeleteEmail from '../modals/B2BEmail/modal-delete-email'
const initialModalState = {
  show: false,
  data: null,
  type: ADD
}
const CardB2BEmail = () => {
  const token = localStorage.getItem('token')
  const [modalState, setModalState] = useState({ ...initialModalState })
  const columns = [
    {
      name: 'Email',
      selector: (row) => row.email,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <p className="text-xs-medium md:text-sm-medium mt-1">{row?.email}</p>
        </div>
      ),
      sortable: true
    },
    {
      name: 'Action',
      ignoreRowClick: true,
      sortable: false,
      right: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <CustomButton
            type="plain"
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => openModal(EDIT, row)}
            label={<IcEdit className="text-gray-700" size={16} />}></CustomButton>
          <CustomButton
            type="plain"
            className="border-primary-500 hover:bg-primary-100"
            onClick={() => openModal(DELETE, row)}
            label={<IcTrash className="text-primary-500 " size={16} />}></CustomButton>
        </div>
      )
    }
  ]
  const { data: emailList = [], refetch } = useQuery({
    queryKey: 'getEmailB2BQuery',
    queryFn: () => getEmailListB2B(token),
    refetchOnWindowFocus: false
  })
  function openModal(type, data = null) {
    setModalState({ type: type, data: data, show: true })
  }
  function closeModal() {
    setModalState({ ...initialModalState })
  }

  const postEmailB2BMutation = useMutation({
    mutationKey: 'postEmailB2B',
    mutationFn: (email) => postEmailB2B(email),
    onSuccess: () => {
      refetch()
      showSuccessMessage('Email berhasil ditambahkan')
      closeModal()
    },
    onError: (err) => {
      if (
        err?.response?.status === 409 &&
        err?.response?.data?.errors?.[0]?.message?.includes('Email already exist!')
      ) {
        return showErrorMessage('Email sudah terdaftar')
      } else if (
        (err.response.status === 400) &
        err?.response?.data?.errors?.[0]?.message?.includes('Exceed Max Company Email')
      ) {
        return showErrorMessage('Email Perusahaan tidak boleh lebih dari 4 Email')
      }
    }
  })
  const putEmailB2BMutation = useMutation({
    mutationKey: 'putEmailB2B',
    mutationFn: (email) => putEmailB2B({ email: email, id: modalState?.data?.id }),
    onSuccess: () => {
      refetch()
      showSuccessMessage('Email berhasil diubah')
      closeModal()
    },
    onError: (err) => {
      if (
        err.status === 409 &&
        err?.response?.data?.errors?.[0]?.message?.includes('Email already exist!')
      ) {
        return showErrorMessage('Email sudah terdaftar')
      }
    }
  })
  const deleteEmailB2BMutation = useMutation({
    mutationKey: 'deleteEmailB2B',
    mutationFn: () => deleteEmailB2B(modalState?.data?.id),
    onSuccess: () => {
      refetch()
      showSuccessMessage('Email berhasil dihapus')
      closeModal()
    },
    onError: () => {
      showErrorMessage('Email gagal dihapus, silahkan coba lagi nanti.')
    }
  })
  function handleSubmit(email) {
    return postEmailB2BMutation.mutate(email)
  }
  function handleDelete() {
    return deleteEmailB2BMutation.mutate()
  }
  function handleEdit(email) {
    return putEmailB2BMutation.mutate(email)
  }

  return (
    <>
      {modalState?.show && modalState.type === DELETE ? (
        <ModalDeleteEmail
          show={modalState?.show}
          data={modalState?.data}
          onCancel={closeModal}
          onSubmit={handleDelete}
        />
      ) : null}
      {modalState?.show && (modalState.type === ADD || modalState?.type === EDIT) ? (
        <ModalCreateEditEmail
          data={modalState?.data?.email}
          show={modalState?.show}
          onCancel={closeModal}
          type={modalState?.type}
          onSubmit={modalState?.type === ADD ? handleSubmit : handleEdit}
        />
      ) : null}
      <CardSectionHeader
        headerTitle={
          <div className="flex justify-between">
            <span>Email Akun</span>
            <CustomButton
              disabled={emailList.length >= 4}
              type="primary"
              label={
                <div className="flex justify-center items-center gap-2 ">
                  <PlusIcon
                    className={`${emailList.length >= 4 ? 'stroke-gray-300' : 'stroke-white'} `}
                  />
                  Tambah Email
                </div>
              }
              onClick={() => {
                openModal(ADD)
              }}
            />
          </div>
        }
        hideDivider>
        <div className="mt-5 w-full">
          <CustomTable hidePagination columns={columns} data={emailList} />
        </div>
      </CardSectionHeader>
    </>
  )
}

export default CardB2BEmail
