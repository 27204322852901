import { useCreditCard } from 'hooks'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  getRangeBookingProcess,
  getRecommendationServicePrice,
  getTransactionDetail,
  postBeliReCreatePayment,
  postInspeksiReCreatePayment,
  postServisCheckout,
  putApprovalRecommendationService,
  putApprovalServiceB2B
} from '../service'
import moment from 'moment'
import { onRupiah } from 'utils/formatCurrency'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
import { getPaymentMethod } from 'utils/getPaymentMethod'
const urlSelect = (type, id) => {
  let url = ''
  switch (type) {
    case 'jual':
      url = `jual-beli-service/website/transactions/sell/${id}`
      break
    case 'beli':
      url = `jual-beli-service/website/transactions/buy/${id}`
      break
    case 'test drive':
      url = `jual-beli-service/website/transactions/test_drive/${id}`
      break
    case 'inspeksi':
      url = `inspeksi-service/website/transaction/detail-transaction/${id}`
      break
    case 'servis':
      url = `servis-service/bookings/${id}?is_show_relation=true&is_show_progress=true&is_show_recommendation_service=true&is_show_history=true&is_show_user=true&is_show_company=true`
      break
    default:
      url = `jual-beli-service/website/transactions/buy/${id}`
  }
  return url
}

// const paymentMethodToString = (value) => {
//   switch (value) {
//     case 'qr_code':
//       return 'QR Code'
//     case 'virtual_account':
//       return 'Virtual Account'
//     case 'card':
//       return 'Credit Card'
//     default:
//       return 'Tunai'
//   }
// }

const useDetailTransaction = (goBack, replace, serviceType, id, getPaymentObject) => {
  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))
  const [unitData, setUnitData] = useState({})
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [recommendationServices, setRecommendationServices] = useState({})
  const [bookingProcessDate, setBookingProcessDate] = useState(null)
  const { creditCards, handleSelectedCreditCard } = useCreditCard(false)
  const [isLoading, setIsLoading] = useState(false)
  const getDetailTransaction = useQuery('getDetailTransaction', {
    queryFn: () => {
      let dataSend = {
        url: urlSelect(serviceType, id),
        token: token
      }
      return getTransactionDetail(dataSend)
    },
    onSuccess: (data) => {
      // JUAL
      // ===========================
      if (serviceType === 'jual') {
        setUnitData({
          ...data,
          booking_steps: data?.catalog_steps?.map((el) => ({
            ...el
          })),
          // service_start_time: data?.test_drive?.test_drive_schedule?.start_hour,
          // service_end_time: data?.test_drive?.test_drive_schedule?.end_hour,
          // inspection_date: data?.test_drive?.test_drive_date,
          // start_time: data?.test_drive?.test_drive_schedule?.start_hour,
          // end_time: {
          //   date: moment(data?.test_drive?.test_drive_date).format('DD MMMM YYYY'),
          //   time: data?.test_drive?.test_drive_schedule?.end_hour,
          // },
          // expired_at: expiredAt,
          // dataPayment: payment,
          latestServiceDate: data?.last_service_status
            ? moment(data.last_service_date).format('DD MMMM YYYY')
            : 'Belum Pernah',
          serviceType: serviceType
        })
      }

      // TEST DRIVE
      // ===========================
      if (serviceType === 'test drive') {
        setUnitData({
          ...data,
          booking_steps: data?.test_drive_steps?.map((el) => ({
            ...el
          })),
          service_start_time: data?.test_drive?.test_drive_schedule?.start_hour,
          service_end_time: data?.test_drive?.test_drive_schedule?.end_hour,
          inspection_date: data?.test_drive?.test_drive_date,
          start_time: data?.test_drive?.test_drive_schedule?.start_hour,
          end_time: {
            date: moment(data?.test_drive?.test_drive_date).format('DD MMMM YYYY'),
            time: data?.test_drive?.test_drive_schedule?.end_hour
          },
          // expired_at: expiredAt,
          // dataPayment: payment,
          serviceType: serviceType
        })
      }
      // BELI
      // ============================
      if (serviceType === 'beli') {
        const expiredAt =
          data.transaction.payment_method === 'qr_code'
            ? data?.transaction?.payment_details?.qr_code?.expired_at
            : data?.transaction?.payment_details?.virtual_account?.expired_at
        const payment = [
          {
            label:
              data?.transaction?.status?.toLowerCase() === 'booking lunas' ||
              data?.transaction?.status?.toLowerCase() === 'selesai'
                ? 'Harga Mobil'
                : 'Harga Layanan',
            value: onRupiah(data.catalog_invoice.subtotal_price),
            addOn: 'Rp.',
            highlight: false
          },
          {
            label: 'Biaya Admin',
            value: onRupiah(data.catalog_invoice.admin_price),
            addOn: 'Rp.',
            highlight: true
          },
          {
            label: `Pajak`,
            value: onRupiah(data.catalog_invoice.tax_fee),
            addOn: 'Rp.',
            highlight: false
          }
          // {
          //   label: 'Pembayaran',
          //   value: paymentMethodToString(data?.transaction?.payment_method),
          //   addOn: '',
          //   highlight: false
          // }
        ]
        if (data?.catalog_invoice?.admin_price === 0) {
          payment.splice(1, 1)
        }
        setUnitData({
          ...data,
          latestServiceDate: data?.last_service_status
            ? moment(data.last_service_date).format('DD MMMM YYYY')
            : 'Belum Pernah',
          end_time: {
            date: moment(data?.catalog?.sold_at).format('DD MMMM YYYY'),
            time: moment(data?.catalog?.sold_at).format('HH:mm')
          },
          invoiceData: {
            invoice_date: moment(data?.catalog_invoice?.created_at).format('DD MMMM YYYY'),
            invoice_no: data?.catalog_invoice?.invoice_number,
            invoice_account: user?.fullname || user?.name,
            invoice_service_type:
              unitData?.transaction?.status?.toLowerCase() === 'booked' ? 'Booking' : 'Beli',
            invoice_unit_name: data?.catalog_invoice?.unit_name,
            invoice_workshop_name: data?.catalog_invoice?.workshop_name,
            invoice_workshop_address: data?.catalog_invoice?.workshop_address,
            invoice_items: [
              {
                name:
                  unitData?.transaction?.status?.toLowerCase() === 'booked'
                    ? 'Harga Layanan'
                    : 'Harga Mobil',
                price:
                  unitData?.transaction?.status?.toLowerCase() === 'booked'
                    ? data?.catalog?.price
                    : data?.catalog_invoice?.subtotal_price,
                qty: 1,
                total:
                  unitData?.transaction?.status?.toLowerCase() === 'booked'
                    ? data?.catalog?.price
                    : data?.catalog_invoice?.subtotal_price
              }
            ],
            invoice_subtotal: data?.catalog_invoice?.subtotal_price,
            invoice_discount: 0,
            invoice_range_price: 0,
            invoice_admin_fee: data?.catalog_invoice?.admin_price,
            invoice_tax: data?.catalog_invoice?.tax_fee,
            invoice_total: data?.catalog_invoice?.total_price
          },
          expired_at: expiredAt,
          dataPayment: payment,
          price_total: data?.catalog_invoice?.total_price,
          unit_name: data?.catalog?.unit_name,
          invoice_checkout: payment,
          serviceType: serviceType
        })
        if (data?.transaction?.payment_method) {
          const { creditCard, paymentType } = getPaymentMethod(
            data?.transaction?.payment_method,
            data?.transaction?.payment_details
          )
          handleSelectedCreditCard(creditCard)
          setSelectedPayment(paymentType)
        }
      }
      // SERVIS
      // ===========================
      if (serviceType === 'servis') {
        let totalPriceRecService = 0
        if (data?.recommendation_services?.length > 0) {
          data?.recommendation_services?.forEach((el) => {
            totalPriceRecService = totalPriceRecService + el.price_total
          })
        }
        const expiredAt = data?.booking_invoice?.due_date
        // bakal payment
        let dataSparepart = []
        let dataLayanan = []
        data?.general_services
          ?.filter((el) => el.status === 'APPROVED' || el.status === 'DEFAULT')
          .forEach((item) => {
            if (item?.sparepart_name !== '') {
              dataSparepart.push({
                label: item.sparepart_name,
                price: item.sparepart_price * item.quantity,
                quantity: item.quantity,
                total: item.sparepart_price_total,
                discounts:
                  item?.voucher_sparepart_detail !== null ? item?.voucher_sparepart_detail : []
              })
            }
            dataLayanan.push({
              label: item.general_service_name,
              price: item.class_price + item.service_price,
              quantity: 0,
              total: item.service_price_total,
              discounts: item?.voucher_service_detail !== null ? item?.voucher_service_detail : []
            })
          })
        data?.packets?.forEach((packet) =>
          packet?.spareparts?.forEach((item) => {
            if (item?.sparepart_name !== '') {
              dataSparepart.push({
                label: item.sparepart_name,
                price: item.sparepart_price * item.quantity,
                quantity: item.quantity,
                total: item.sparepart_price_total,
                discounts:
                  item?.voucher_sparepart_detail !== null ? item?.voucher_sparepart_detail : []
              })
            }
            dataLayanan.push({
              label: item.general_service_name,
              price: item.class_price + item.service_price,
              quantity: 0,
              total: item.service_price_total,
              discounts: item?.voucher_service_detail !== null ? item?.voucher_service_detail : []
            })
          })
        )

        let payment = []
        const oldPayment = [
          // {
          //   label: `Harga Jasa (${data?.class_name})`,
          //   value: onRupiah(data?.class_price_total),
          //   addOn: 'Rp.',
          //   highlight: false
          // },
          {
            label: `Biaya Jarak (${data?.start_distance} km - ${data?.end_distance} km)`,
            value: onRupiah(data?.distance_price),
            addOn: 'Rp.',
            highlight: false
          }
        ]
        if (data?.company_discount_total) {
          oldPayment.push({
            label: 'Diskon Perusahaan',
            value: `(${onRupiah(data?.company_discount_total)})`,
            addOn: 'Rp.',
            highlight: true
          })
        }
        oldPayment.push({
          label: 'Pajak',
          value: onRupiah(data?.tax),
          addOn: 'Rp.',
          highlight: false
        })
        if (data?.distance_id > 0) {
          payment = oldPayment
        } else {
          payment = oldPayment.filter((_, index) => index !== 0)
        }

        setUnitData({
          ...data,
          catalog_invoice: {
            invoice_number: data?.booking_invoice?.invoice_no,
            created_at:
              data?.booking_invoice?.transaction_detail?.paid_at ||
              data?.booking_invoice?.updated_at,
            catalog_invoice: ''
          },
          booking_steps: data?.booking_histories?.map((el) => ({
            ...el,
            created_at: el.completion_time,
            // description: capitalize(el.status.replaceAll('_', ' '))
            description: el.name
          })),
          invoiceData: {
            invoice_date: moment(data?.booking_invoice?.created_at).format('DD MMMM YYYY'),
            invoice_no: data?.booking_invoice?.invoice_no,
            invoice_account: user?.fullname || user?.name,
            invoice_service_type: 'Servis',
            invoice_unit_name: data?.unit_name,
            invoice_workshop_name: data?.workshop_name,
            invoice_workshop_address: data?.workshop_address,
            invoice_sparepart_items: dataSparepart,
            invoice_layanan_items: dataLayanan,
            invoice_subtotal: data?.item_total,
            invoice_discount: null,
            invoice_discount_voucher: data?.voucher_discount_total,
            invoice_discount_company: data?.company_discount_total,
            invoice_range_price: data?.distance_price !== null ? data?.distance_price : 0,
            invoice_admin_fee: data?.admin_fee,
            invoice_tax: data?.tax,
            invoice_total: data?.price_total
          },
          catalog: {
            unit_id: data?.unit_id,
            unit_name: data?.unit_name,
            thumbnail_filename: data?.unit_information?.thumbnail_filename,
            production_year: data?.unit_information?.production_year,
            last_service_status: data?.unit_information?.last_service_status,
            police_number: data?.unit_information?.police_number,
            transmission_name: data?.unit_information?.transmission?.name
          },
          end_time: {
            date: moment(data?.inspection_date).format('DD MMMM YYYY'),
            time: moment(data?.end_time).format('HH:mm')
          },
          grandTotalRecommendationPrice: data.price_total,
          expired_at: expiredAt,
          dataPayment: [...payment],
          dataSpareparts: dataSparepart,
          dataLayanans: dataLayanan,
          // dataSubTotal: data?.item_total + data?.class_price_total + data?.admin_fee + data?.distance_price,
          dataSubTotal: data?.item_total,
          grandTotalPayment: data?.price_total,
          price_total: data?.booking_invoice?.price_total,
          unit_name: data?.unit_name,
          invoice_checkout: payment,
          serviceType: serviceType,
          latestServiceDate:
            data?.latest_service_date !== null
              ? moment(data.latest_service_date).format('DD MMMM YYYY')
              : 'Belum Pernah'
        })
      }
      // INSPEKSI
      // ===========================
      if (serviceType === 'inspeksi') {
        const expiredAt =
          data?.payment_method === 'qr_code'
            ? data?.payment_details?.qr_code?.expired_at
            : data?.payment_details?.virtual_account?.expired_at
        const payment = [
          {
            label: 'Biaya layanan',
            value: onRupiah(data.inspection_fee),
            addOn: 'Rp.',
            highlight: false
          },
          {
            label: 'Biaya Admin',
            value: onRupiah(data.admin_fee),
            addOn: 'Rp.',
            highlight: false
          },
          {
            label: 'Biaya Jarak',
            value: onRupiah(data.distance_price),
            addOn: 'Rp.',
            highlight: false
          },
          {
            label: 'Pajak',
            value: onRupiah(data?.tax_fee),
            addOn: 'Rp.',
            highlight: false
          }
          // { label: 'Pembayaran', value: data?.payment_method_string, addOn: '', highlight: false }
        ]
        if (data?.admin_fee === 0) {
          payment.splice(1, 1)
        }
        if (data?.distance_price === 0) {
          payment.splice(1, 1)
        }
        setUnitData({
          ...data,
          service_start_time: moment.utc(data.start_time).format('HH:mm'),
          service_end_time: moment.utc(data.end_time).format('HH:mm'),
          catalog_invoice: {
            invoice_number: data?.invoice_number,
            created_at: data?.paid_at,
            total_price: data?.price
          },
          booking_service_statuses: data?.inspectionEventProgress.map((el) => ({
            ...el,
            completion_time: el.time
          })),
          service_date: data.inspection_date,
          catalog: {
            unit_id: data?.unit_id,
            unit_name: data?.unit_name,
            thumbnail_filename: data?.thumbnail_filename,
            production_year: data?.production_year,
            last_service_status: data?.latest_service_date !== '0001/01/01',
            police_number: data?.unit_police_number,
            transmission_name: data?.unit_transmission
          },
          // inspection_result?.inspectionCategoryList
          inspection_result: {
            inspectionCategoryList: data?.inspectionResultList?.inspectionCategoryList || []
          },
          booking_steps: data.inspectionTransactionHistory.map((el) => ({
            ...el,
            description: el.name,
            created_at: el.time
          })),
          invoiceData: {
            invoice_date: moment(data?.paid_at).format('DD MMMM YYYY'),
            invoice_no: data?.invoice_number,
            invoice_account: user?.fullname || user?.name,
            invoice_service_type: 'Inspeksi',
            invoice_unit_name: data?.unit_name,
            invoice_workshop_name: data?.place,
            invoice_workshop_address: data?.workshop_address,
            invoice_items: [
              {
                name: 'Biaya Layanan',
                price: data?.inspection_fee,
                qty: 1,
                total: data?.inspection_fee
              }
            ],
            invoice_subtotal: data?.inspection_fee,
            invoice_range_price: data?.distance_price,
            invoice_discount: 0,
            invoice_admin_fee: data?.admin_fee,
            invoice_tax: data?.tax_fee,
            invoice_total: data?.price
          },
          expired_at: expiredAt,
          dataPayment: payment,
          invoice_checkout: payment,
          serviceType: serviceType,
          price_total: data?.price,
          latestServiceDate:
            data?.latest_service_date !== '0001/01/01'
              ? moment(data.latest_service_date).format('DD MMMM YYYY')
              : 'Belum Pernah'
        })
        if (data?.payment_method) {
          const { creditCard, paymentType } = getPaymentMethod(
            data?.payment_method,
            data?.payment_details
          )
          handleSelectedCreditCard(creditCard)
          setSelectedPayment(paymentType)
        }
      }
    },
    onError: () => {
      showErrorMessage('Transaksi telah dihapus, silahkan hubungi tim BirdMobil')
      // replace('/transaction')
      goBack()
    }
  })

  useQuery({
    queryKey: 'getRangeBookingProcess',
    queryFn: () => getRangeBookingProcess(unitData?.catalog?.id),
    onSuccess: (res) => {
      setBookingProcessDate(res)
      // setBookingProcessDate(data)
    },
    enabled: !!unitData?.catalog?.id && serviceType === 'beli',
    staleTime: Infinity
  })

  const getRecommendationService = useQuery('getRecommendationServicePrice', {
    queryFn: () => {
      const dataSend = {
        id: id,
        token: token
      }
      return getRecommendationServicePrice(dataSend)
    },
    onSuccess: (data) => {
      if (data?.recommendation_services == null) {
        return
      }
      let recommendationServices = {}
      Object.entries(data.recommendation_services).forEach(([key, value]) => {
        let dataLayanan = []
        let dataSparepart = []
        if (value?.length == 0) {
          return
        }
        value.map((el) => {
          if (!el?.is_free)
            dataSparepart.push({
              label: el.sparepart_name,
              price: el.sparepart_price * el.quantity,
              quantity: el.quantity,
              total: el.sparepart_price_total,
              discounts: el?.voucher_sparepart_detail !== null ? el?.voucher_sparepart_detail : []
            })
          dataLayanan.push({
            label: el.general_service_name,
            price: el.class_price + el.service_price,
            quantity: 0,
            total: el.service_price_total,
            discounts: el?.voucher_service_detail !== null ? el?.voucher_service_detail : []
          })
        })
        recommendationServices = {
          ...recommendationServices,
          [key]: { dataSpareparts: dataSparepart, dataLayanans: dataLayanan }
        }
      })
      setRecommendationServices((prev) => ({
        ...prev,
        has_recommendations: data?.has_recommendations,
        recommendation_services: recommendationServices,
        grand_price_total: data?.grand_price_total
      }))
    },
    enabled: serviceType === 'servis'
  })

  const putRecommendationService = useMutation({
    mutationKey: 'putApprovalRecommendationService',
    mutationFn: (boolean) => putApprovalRecommendationService(id, boolean),
    onSuccess: (data) => {
      if (data?.recommendation_services?.[0]?.status === 'REJECTED') {
        showSuccessMessage('Servis telah berhasil diabaikan')
      } else {
        showSuccessMessage('Servis telah berhasil dikonfirmasi')
      }
      getDetailTransaction.refetch()
      getRecommendationService.refetch()
    }
  })

  const putApprovalService = useMutation({
    mutationKey: 'putApprovalServiceB2B',
    mutationFn: (boolean) => putApprovalServiceB2B(id, boolean),
    onSuccess: (data) => {
      if (data?.status === 'REJECTED') {
        showSuccessMessage('Booking Servis telah berhasil diabaikan')
      } else {
        showSuccessMessage('Servis telah berhasil dikonfirmasi')
      }
      getDetailTransaction.refetch()
    }
  })

  const postBeliPaymentMethod = useMutation({
    mutationFn: (dataSend) =>
      postBeliReCreatePayment(unitData?.transaction.transaction_id, dataSend),
    onSuccess: (data) => {
      navigateBeliPayment(data)
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      showErrorMessage(
        'Terjadi kesalahan dalam melakukan pembayaran, silahkan kontak admin BirdMobil'
      )
    }
  })

  const postInspeksiPaymentMethod = useMutation({
    mutationFn: (dataSend) => postInspeksiReCreatePayment(unitData?.transaction_id, dataSend),
    onSuccess: (data) => {
      navigateInspeksiPayment(data)
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      showErrorMessage(
        'Terjadi kesalahan dalam melakukan pembayaran, silahkan kontak admin BirdMobil'
      )
    }
  })

  const postServisPaymentMethod = useMutation({
    mutationFn: (dataSend) => postServisCheckout(unitData?.booking_invoice_id, dataSend),
    onSuccess: (data) => {
      navigateServisPayment(data)
    },
    onError: () => {
      setIsLoading(false)
      showErrorMessage(
        'Terjadi kesalahan dalam melakukan pembayaran, silahkan kontak admin BirdMobil'
      )
    }
  })

  function handleConfirmationPayment() {
    const dataSend = getPaymentObject()
    if (serviceType === 'jual' || serviceType === 'beli' || serviceType === 'test drive') {
      return postBeliPaymentMethod.mutate(dataSend)
    } else if (serviceType === 'servis') {
      return postServisPaymentMethod.mutate(dataSend)
    } else if (serviceType === 'inspeksi') {
      return postInspeksiPaymentMethod.mutate(dataSend)
    }
  }

  function handleLakukanPembayaran() {
    if (serviceType === 'beli' || serviceType === 'Beli') {
      navigateBeliPayment(unitData)
    } else if (serviceType === 'inspeksi' || serviceType === 'Inspeksi') {
      navigateInspeksiPayment(unitData)
    } else if (serviceType.toLowerCase() === 'servis') {
      navigateServisPayment(unitData)
    }
  }

  // function getPaymentMethod(paymentMethod, paymentDetails) {
  //   let paymentType = null
  //   let creditCard = null
  //   let paymentMethodList = paymentList.find((payment_method_list) => {
  //     return payment_method_list.id === paymentMethod
  //   })?.options

  //   if (paymentMethod === 'virtual_account') {
  //     paymentType = paymentMethodList.filter((paymentType) => {
  //       let provider = paymentType.id.split('-')[1]
  //       return provider === paymentDetails[paymentMethod]?.bank_code
  //     })[0]
  //   } else if (paymentMethod === 'qr_code') {
  //     paymentType = paymentMethodList[0]
  //   } else if (paymentMethod === 'card') {
  //     paymentType = paymentMethodList[0]
  //     creditCard = creditCards.find((card) => card?.id === paymentDetails?.card?.linked_card_id)
  //   }
  //   return { creditCard, paymentType }
  // }

  function handleApprovalService(value) {
    putApprovalService.mutate(value)
  }
  function handleApprovalRecommendationService(value) {
    putRecommendationService.mutate(value)
  }

  function navigateBeliPayment(data) {
    const { creditCard, paymentType } = getPaymentMethod(
      data?.payment_method ? data?.payment_method : data?.transaction?.payment_method,
      data?.payment_details ? data?.payment_details : data?.transaction?.payment_details,
      creditCards
    )
    if (data?.payment_details?.card?.authorization_url) {
      window.open(data?.payment_details?.card?.authorization_url)
    } else if (data?.transaction?.payment_details?.card?.authorization_url) {
      window.open(data?.transaction?.payment_details?.card?.authorization_url)
    }
    replace(
      `${
        unitData?.unit_type === 'catalog'
          ? '/beli/mobil-bekas/booking/payment'
          : '/beli/ex-taxi/booking/payment'
      }`,
      {
        paymentDetails: {
          payment_method: data?.payment_method
            ? data?.payment_method
            : data?.transaction?.payment_method,
          payment_status: data?.payment_status
            ? data?.payment_status
            : data?.transaction?.payment_status,
          payment_details: data?.payment_details
            ? data?.payment_details
            : data?.transaction?.payment_details
        },
        totalPrice: unitData?.catalog_invoice?.total_price,
        taxPrice: null,
        price: {
          tax: null,
          bookingPrice: unitData?.catalog_invoice?.subtotal_price,
          admin_fee: unitData?.catalog_invoice?.admin_price
        },
        selectedPayment: paymentType,
        selectedCreditCard: creditCard,
        transactionId: data?.transaction_id
          ? data?.transaction_id
          : data?.transaction?.transaction_id,
        serviceType: serviceType,
        id: id
      }
    )
  }

  function navigateServisPayment(data) {
    const { creditCard, paymentType } = getPaymentMethod(
      data?.transaction_detail?.payment_method ||
        data?.booking_invoice?.transaction_detail?.payment_method,
      data?.transaction_detail?.payment_details ||
        data?.booking_invoice?.transaction_detail?.payment_details,
      creditCards
    )

    if (data?.transaction_detail?.payment_details?.card?.authorization_url) {
      window.open(data?.transaction_detail?.payment_details?.card?.authorization_url)
    } else if (
      data?.booking_invoice?.transaction_detail?.payment_details?.card?.authorization_url
    ) {
      window.open(
        data?.booking_invoice?.transaction_detail?.payment_details?.card?.authorization_url
      )
    }

    replace('/servis/servis-mobil/payment', {
      paymentDetails: {
        payment_method:
          data?.transaction_detail?.payment_method ||
          data?.booking_invoice?.transaction_detail?.payment_method,
        payment_status:
          data?.transaction_detail?.payment_status ||
          data?.booking_invoice?.transaction_detail?.payment_status,
        payment_details:
          data?.transaction_detail?.payment_details ||
          data?.booking_invoice?.transaction_detail?.payment_details
      },
      totalPrice: unitData?.price_total,
      listDetailPayment: [
        {
          label: 'Sub total',
          value: onRupiah(unitData?.item_total),
          addOn: 'Rp.',
          highlight: false
        },
        ...unitData.dataPayment
      ],
      selectedPayment: paymentType,
      selectedCreditCard: creditCard,
      transactionId: data?.transaction_id || unitData?.booking_invoice?.transaction_id,
      serviceType: serviceType,
      id: id
    })
  }

  function navigateInspeksiPayment(data) {
    const { creditCard, paymentType } = getPaymentMethod(
      data?.payment_method,
      data?.payment_details,
      creditCards
    )
    if (data?.payment_details?.card?.authorization_url) {
      window.open(data?.payment_details?.card?.authorization_url)
    }

    replace('/inspeksi/form/payment', {
      paymentDetails: {
        payment_method: data?.payment_method,
        payment_status: data?.payment_status,
        payment_details: data?.payment_details
      },
      totalPrice: unitData?.catalog_invoice.total_price,
      price: {
        tax: (
          (unitData?.catalog_invoice.total_price /
            (unitData?.price - unitData?.admin_fee - unitData?.tax_fee) -
            1) *
          100
        ).toFixed(),
        inspection: unitData?.price - unitData?.admin_fee - unitData?.tax_fee,
        admin_fee: unitData?.admin_fee
      },
      taxPrice: unitData?.tax_fee,
      range_price: unitData?.distance_price === 0 ? undefined : unitData?.distance_price,
      selectedPayment: paymentType,
      selectedCreditCard: creditCard,
      transactionId: data?.transaction_id,
      serviceType: serviceType,
      id: id
    })
  }

  return {
    handleApprovalRecommendationService,
    handleApprovalService,
    handleLakukanPembayaran,
    unitData,
    putApprovalService,
    recommendationServices,
    putRecommendationService,
    bookingProcessDate,
    isLoading,
    handleConfirmationPayment,
    checkoutIsLoading:
      postServisPaymentMethod.isLoading ||
      postBeliPaymentMethod.isLoading ||
      postInspeksiPaymentMethod.isLoading
  }
}

export default useDetailTransaction
