export const accountNumberBirdMobil = [
  {
    id: 0,
    bankName: 'BCA',
    accountName: 'PUSAKA MITRA MOBILINDO PT',
    accountNumber: '622-0567549'
  },
  {
    id: 1,
    bankName: 'PERMATA',
    accountName: 'PUSAKA MITRA MOBILINDO PT',
    accountNumber: '00702574773'
  }
]
