import { Collapse, Divider, Spin, Tooltip } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'
import { IoMdInformationCircleOutline } from 'react-icons/io'
const { Panel } = Collapse
const titleName = {
  create: 'Tambah',
  delete: 'Hapus',
  update: 'Edit'
}
function CardRecommendationService({
  data,
  handleApprovalRecommendationService,
  putRecommendationService
}) {
  return (
    <CardSectionHeader headerTitle="Rekomendasi Servis" className="order-2 md:order-none">
      <div className="text-xs-regular text-gray-600 grid grid-cols-1 gap-3 pb-4">
        <div className="overflow-x-scroll md:overflow-auto">
          <div className="border-spacing-2 w-full whitespace-nowrap border-separate md:whitespace-normal">
            <div className="text-md-medium text-gray-900 grid grid-cols-[1fr_0.25fr_0.5fr_0.5fr_0.75fr] gap-x-2 w-full px-4 mb-4">
              <span>Item</span>
              <span>QTY</span>
              <span>Harga</span>
              <span>Disc</span>
              <span className="text-right">Total</span>
            </div>

            {Object?.entries(data?.recommendation_services)?.length > 0
              ? Object?.entries(data?.recommendation_services)?.map(([key, list]) => (
                  <Collapse
                    className="bg-white border-0"
                    expandIconPosition="end"
                    key={`${key}-recommendation-collapse`}>
                    <Panel header={<span className="text-md-medium">{titleName[key]} Servis</span>}>
                      {list?.dataSpareparts?.length > 0 && (
                        <>
                          <p className="text-md-medium text-gray-900 mb-2">Sparepart</p>
                          {list?.dataSpareparts?.map((item, index) => (
                            <div
                              className="grid grid-cols-[1fr_0.25fr_0.5fr_0.5fr_0.75fr] gap-x-2 text-left text-md-regular text-gray-700 mb-5"
                              key={index}>
                              <span className=" align-top pb-2">{item.label}</span>
                              <span className=" align-top">
                                {item.quantity !== 0 ? `x${item.quantity}` : '-'}
                              </span>
                              <span className="align-top">Rp {onRupiah(item.price)}</span>
                              <div className="pb-2 align-top text-primary-500">
                                {item?.discounts?.length > 0
                                  ? item.discounts.map((el, i) => (
                                      <p key={i}>
                                        {el.price_total > 0
                                          ? `- Rp. ${onRupiah(el.price_total)} ${
                                              el.discount_type === 'PERCENT'
                                                ? `(${el?.discount}%)`
                                                : ''
                                            }`
                                          : '-'}
                                      </p>
                                    ))
                                  : '-'}
                              </div>
                              <span className="text-right align-top">
                                Rp {onRupiah(item.total)}
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                      {list?.dataLayanans?.length > 0 && (
                        <>
                          <p className="text-md-medium text-gray-900 mt-5 mb-2">Layanan</p>
                          {list?.dataLayanans?.map((item, index) => (
                            <div
                              className="text-left grid grid-cols-[1fr_0.25fr_0.5fr_0.5fr_0.75fr] gap-x-2 text-md-regular text-gray-700 mb-5"
                              key={index}>
                              <span className=" align-top pb-2">{item.label}</span>
                              <span className=" align-top">
                                {item.quantity !== 0 ? `x${item.quantity}` : '-'}
                              </span>
                              <span className=" align-top">Rp {onRupiah(item.price)}</span>
                              <div className="pb-2 align-top text-primary-500">
                                {item?.discounts?.length > 0
                                  ? item.discounts.map((el, i) => (
                                      <p key={i}>
                                        {el.price_total > 0
                                          ? `- Rp. ${onRupiah(el.price_total)} ${
                                              el.discount_type === 'PERCENT'
                                                ? `(${el?.discount}%)`
                                                : ''
                                            }`
                                          : '-'}
                                      </p>
                                    ))
                                  : '-'}
                              </div>
                              <span className="text-right align-top">
                                Rp {onRupiah(item.total)}
                              </span>
                            </div>
                          ))}
                        </>
                      )}{' '}
                    </Panel>
                  </Collapse>
                ))
              : null}
          </div>
        </div>
        <div className="flex flex-col justify-end items-end py-4">
          <Divider className="m-0 my-5" />
          <div className="flex items-center gap-2 text-md-semibold">
            <span className="text-md-regular text-gray-700">Grand Total :</span>
            <span className="text-lg-medium text-gray-900">
              Rp {onRupiah(data?.grand_price_total)}
            </span>
            <Tooltip
              className="cursor-pointer"
              title="Harga ini sudah termasuk dengan pemotongan voucher (apabila ada) dan tambahan pajak">
              <IoMdInformationCircleOutline className="text-primary-500 w-5 h-5" />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Spin spinning={putRecommendationService?.isLoading}>
          <CustomButton
            onClick={() => handleApprovalRecommendationService(false)}
            type="plain"
            label={'Abaikan'}
          />
        </Spin>
        <Spin spinning={putRecommendationService?.isLoading}>
          <CustomButton
            onClick={() => handleApprovalRecommendationService(true)}
            label={'Setujui'}
          />
        </Spin>
      </div>
    </CardSectionHeader>
  )
}

export default CardRecommendationService
