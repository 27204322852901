import React from 'react'

const IcEdit = ({ className, size = 20 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1984_24477)">
      <path
        d="M9.16675 3.33417H3.33341C2.89139 3.33417 2.46746 3.50977 2.1549 3.82233C1.84234 4.13489 1.66675 4.55881 1.66675 5.00084V16.6675C1.66675 17.1095 1.84234 17.5335 2.1549 17.846C2.46746 18.1586 2.89139 18.3342 3.33341 18.3342H15.0001C15.4421 18.3342 15.866 18.1586 16.1786 17.846C16.4912 17.5335 16.6667 17.1095 16.6667 16.6675V10.8342M15.4167 2.08417C15.7483 1.75265 16.1979 1.56641 16.6667 1.56641C17.1356 1.56641 17.5852 1.75265 17.9167 2.08417C18.2483 2.41569 18.4345 2.86533 18.4345 3.33417C18.4345 3.80301 18.2483 4.25265 17.9167 4.58417L10.0001 12.5008L6.66675 13.3342L7.50008 10.0008L15.4167 2.08417Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1984_24477">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IcEdit
