import { Input } from 'antd'
import React from 'react'

function FormInput({ data }) {
  const { label, mandatory, ...inputProps } = data
  return (
    <div className="grid grid-cols-1 gap-2">
      <p className="text-sm-medium text-gray-700">
        {label} {mandatory ? <span className="text-danger-500">*</span> : null}
      </p>
      <Input className="rounded-md" {...inputProps} />
    </div>
  )
}

export default FormInput
