import { Divider } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'
const fontItemTitleStyle = 'font-semibold text-gray-900'
const fontItemValueStyle = ' text-gray-700 '
function CardDetailPaymentService({
  data,
  dataSpareparts,
  dataLayanans,
  dataSubTotal,
  dataPayment,
  total
}) {
  return (
    <CardSectionHeader headerTitle="Rincian Pembayaran" className="relative">
      <div className="overflow-x-scroll md:overflow-auto">
        <div className={` gap-1 grid grid-cols-11`}>
          <div className={`text-left col-span-4`}>
            <span className={fontItemTitleStyle}>Item</span>
          </div>
          <div className="text-left">
            <span className={fontItemTitleStyle}>Qty</span>
          </div>
          <div className="text-left col-span-2">
            <span className={fontItemTitleStyle}>Harga</span>
          </div>
          <div className="text-left col-span-2">
            <span className={fontItemTitleStyle}>Discount</span>
          </div>
          <div className="text-right col-span-2">
            <span className={fontItemTitleStyle}>Total</span>
          </div>
        </div>
        {dataSpareparts?.length > 0 ? (
          <>
            <div className={`pt-3 ${fontItemTitleStyle}`}>Sparepart</div>
            {dataSpareparts?.map((sparepart, index) => (
              <div key={index} className={`pt-1 grid grid-cols-11 gap-1`}>
                <div className={`text-left col-span-4`}>
                  <span className={fontItemValueStyle}>{sparepart?.label}</span>
                </div>
                <div className=" text-left">
                  <span className={fontItemValueStyle}>
                    {sparepart?.quantity ? `x${sparepart?.quantity}` : ''}
                  </span>
                </div>
                <div className=" text-left col-span-2">
                  <span className={fontItemValueStyle}>Rp. {onRupiah(sparepart?.price)}</span>
                </div>
                <div className=" text-left col-span-2">
                  <span className={fontItemValueStyle + ' text-primary-500'}>
                    {sparepart?.discounts?.length > 0
                      ? sparepart.discounts.map((el, i) => (
                          <p key={i}>
                            {el.price_total > 0
                              ? `- Rp. ${onRupiah(el.price_total)} ${
                                  el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''
                                }`
                              : '-'}
                          </p>
                        ))
                      : '-'}
                  </span>
                </div>
                <div className=" text-right col-span-2">
                  <span className={fontItemValueStyle}>Rp. {onRupiah(sparepart?.total)}</span>
                </div>
              </div>
            ))}
            <div className="grid grid-cols-5">
              {data?.sparepart_general_voucher_discount_total ? (
                <>
                  <Divider className="m-0 my-2 col-span-5" />
                  <span className={`${fontItemValueStyle} col-span-2`}>Voucher Sparepart</span>
                  <span className={`${fontItemValueStyle} text-right text-primary-500 col-span-3`}>
                    - Rp. {onRupiah(data?.sparepart_general_voucher_discount_total ?? 0)}
                  </span>
                </>
              ) : null}
              <>
                <Divider className="m-0 my-2 col-span-5" />
                <span className={`${fontItemTitleStyle} col-span-2`} colSpan={4}>
                  Subtotal Sparepart
                </span>
                <span className={`${fontItemTitleStyle} text-right col-span-3`}>
                  Rp. {onRupiah(data?.sparepart_total ?? 0)}
                </span>
              </>
            </div>
          </>
        ) : null}
        {dataLayanans?.length > 0 ? (
          <>
            <div className={`pt-3 ${fontItemTitleStyle}`}>Layanan</div>
            {dataLayanans?.map((service, index) => (
              <div key={index} className={`pt-1 grid grid-cols-11 gap-1`}>
                <div className={`text-left col-span-4`}>
                  <span className={fontItemValueStyle}>{service?.label}</span>
                </div>
                <div className=" text-left">
                  <span className={fontItemValueStyle}>
                    {service?.quantity ? `x${service?.quantity}` : ''}
                  </span>
                </div>
                <div className=" text-left col-span-2">
                  <span className={fontItemValueStyle}>Rp. {onRupiah(service?.price)}</span>
                </div>
                <div className=" text-left col-span-2">
                  <span className={fontItemValueStyle + ' text-primary-500'}>
                    {service?.discounts?.length > 0
                      ? service.discounts.map((el, i) => (
                          <p key={i}>
                            {el.price_total > 0
                              ? `- Rp. ${onRupiah(el.price_total)} ${
                                  el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''
                                }`
                              : '-'}
                          </p>
                        ))
                      : '-'}
                  </span>
                </div>
                <div className=" text-right col-span-2">
                  <span className={fontItemValueStyle}>Rp. {onRupiah(service?.total)}</span>
                </div>
              </div>
            ))}
            <div className="grid grid-cols-5">
              {data?.service_general_voucher_discount_total ? (
                <>
                  <Divider className="m-0 my-2 col-span-5" />
                  <span className={`${fontItemValueStyle} col-span-2`}>Voucher Layanan</span>
                  <span className={`${fontItemValueStyle} text-right text-primary-500 col-span-3`}>
                    - Rp. {onRupiah(data?.service_general_voucher_discount_total ?? 0)}
                  </span>
                </>
              ) : null}
              <>
                <Divider className="m-0 my-2 col-span-5" />
                <span className={`${fontItemTitleStyle} col-span-2`} colSpan={4}>
                  Subtotal Layanan
                </span>
                <span className={`${fontItemTitleStyle} text-right col-span-3`}>
                  Rp. {onRupiah(data?.service_price_total ?? 0)}
                </span>
              </>
            </div>
          </>
        ) : null}
      </div>
      <div className="flex mt-7 justify-between  text-gray-700 w-100 font-semibold">
        <p>Subtotal</p>
        <p>Rp. {onRupiah(dataSubTotal)}</p>
      </div>
      <Divider className="m-0 my-5" />
      {dataPayment?.map((item, index) => (
        <div className="flex mb-2 justify-between font-semibold text-gray-700 w-100" key={index}>
          <p>{item.label}</p>
          <p className={`${item.label.toLowerCase().includes('diskon') ? 'text-primary-500' : ''}`}>
            {item.label.toLowerCase().includes('diskon') ? '- ' : ''}Rp. {item.value}
          </p>
        </div>
      ))}
      <Divider className="m-0 my-5" />
      <div className="flex justify-between ">
        <p className="text-gray-900 font-semibold">Total Pembayaran</p>
        <p className="text-warning-500 text-md-semibold text-left">Rp. {total}</p>
      </div>
    </CardSectionHeader>
  )
}

export default CardDetailPaymentService
