import API from 'services'

const getReportList = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      },
      params: args.params
    }
  )
  return response
}
const getReportUnits = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/report/total-unit/detail`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      },
      params: args.params
    }
  )
  return response
}
const getTotalExpenses = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports/total-expenses`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
      // params: args.params
    }
  )
  return response.data
}
const getTotalBills = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports/total-bills`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
      // params: args.params
    }
  )
  return response.data
}
const getTotalCars = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/report/total-unit`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
      // params: args.params
    }
  )
  return response.data
}

const getUsersReport = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      },
      params: args.params
    }
  )
  return response.data
}

const getLatestB2BReport = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports/report`
  )
  return response.data
}
const postGenerateB2BReport = async (args) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports/generate-report?month=${args.params.month}&year=${args.params.year}&user_id=${args.params.user_id}&payment_status=${args.params.payment_status}`
  )
  return response.data
}

export {
  getReportList,
  getReportUnits,
  getTotalExpenses,
  getTotalBills,
  getUsersReport,
  getTotalCars,
  getLatestB2BReport,
  postGenerateB2BReport
}
