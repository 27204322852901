import { Collapse, Divider } from 'antd'
import { ArrowRightIcon, ChevronDownIcon, ChevronRightIcon, XCircleIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import { onRupiah } from 'utils/formatCurrency'
const { Panel } = Collapse

function CardServiceCart({ cartList, removeCartItem, selectedVoucher, handleModalVoucherOpen }) {
  function renderPacketServiceCart(general_services) {
    let layananList = []
    let sparepartList = []
    general_services?.map((item) => {
      if (item?.sparepart_name !== '') {
        sparepartList.push({
          label: item.sparepart_name,
          price: item.sparepart_price * item.quantity,
          quantity: item.quantity,
          total: item.sparepart_price_total,
          discounts: item?.voucher_sparepart_detail !== null ? item?.voucher_sparepart_detail : []
        })
      }
      layananList.push({
        label: item.general_service_name,
        price: item.class_price + item.service_price,
        quantity: 0,
        total: item.service_price_total,
        discounts: item?.voucher_service_detail !== null ? item?.voucher_service_detail : []
      })
    })
    return (
      <>
        <div className="text-xs-regular text-gray-500 pb-2">
          {sparepartList?.length > 0 && (
            <>
              <p className="text-xs-medium">Sparepart</p>
              {sparepartList?.map((item, key) => (
                <div key={key} className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
                  <p>{item?.label}</p>
                  <div className="flex justify-center ">x{item?.quantity}</div>
                  {
                    <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                      {item?.discounts?.length > 0
                        ? item?.discounts?.map((el, i) => (
                            <p key={i}>
                              {el.price_total > 0
                                ? `- ${
                                    el.discount_type === 'PERCENT'
                                      ? `(${el?.discount}%)`
                                      : `Rp. ${onRupiah(el.price_total)}`
                                  }`
                                : ''}
                            </p>
                          ))
                        : ''}
                    </div>
                  }
                  <p className="text-right">Rp {onRupiah(item?.total)}</p>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="text-xs-regular text-gray-500 pb-2">
          <p className="text-xs-medium">Layanan</p>
          {layananList.map((item, key) => (
            <div key={key} className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
              <p>{item?.label}</p>
              <span></span>
              {
                <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                  {item?.discounts?.length > 0
                    ? item?.discounts?.map((el, i) => (
                        <p key={i}>
                          {el.price_total > 0
                            ? `- ${
                                el.discount_type === 'PERCENT'
                                  ? `(${el?.discount}%)`
                                  : `Rp. ${onRupiah(el.price_total)}`
                              }`
                            : ''}
                        </p>
                      ))
                    : ''}
                </div>
              }
              <p className="text-right">Rp {onRupiah(item?.total)}</p>
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <div>
      <CardSectionHeader headerTitle={'Keranjang'} hideDivider>
        <div className="mt-5">
          {Object.entries(cartList).length === 0 ? (
            <div className="flex items-center text-center justify-center text-sm-medium text-gray-500 h-[167px]">
              Anda belum menambahkan Servis
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {/* servis */}

              {cartList?.packets?.map((packet, key) => (
                <Collapse
                  key={packet.packet_id}
                  accordion
                  bordered={false}
                  className="text-lg-medium flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white"
                  expandIconPosition={'start'}
                  expandIcon={(props) => {
                    return props.isActive ? (
                      <ChevronDownIcon className={'stroke-gray-400 w-3 h-3'} />
                    ) : (
                      <ChevronRightIcon className={'stroke-gray-400 w-3 h-3'} />
                    )
                  }}>
                  <Panel
                    style={{ border: '1px solid #EAECF0' }}
                    className="bg-gray-50"
                    header={
                      <div
                        key={packet.packet_id}
                        className="flex items-center justify-between xl:gap-4 p-1 bg-gray-50">
                        {/* content */}
                        <div className="grid grid-cols-1 w-full gap-0">
                          <div className="grid grid-cols-2 gap-4 text-xs-medium xl:text-sm-medium text-gray-700">
                            <p>{packet.packet_name}</p>
                            <p className="whitespace-nowrap text-right">
                              Rp {onRupiah(packet.price)}
                            </p>
                          </div>
                        </div>

                        {/* remove button */}
                        <div
                          className="w-[20px] cursor-pointer text-center flex justify-center items-center"
                          onClick={(e) => {
                            removeCartItem('packetService', packet.packet_id)
                            e.stopPropagation()
                          }}>
                          <XCircleIcon className="stroke-gray-400" />
                        </div>
                      </div>
                    }
                    key={key}>
                    {packet?.spareparts?.length > 0 && (
                      <div>{renderPacketServiceCart(packet?.spareparts)}</div>
                    )}
                  </Panel>
                </Collapse>
              ))}
              {cartList?.general_services?.map((service, key) => (
                <Collapse
                  key={service?.general_service_id}
                  accordion
                  bordered={false}
                  className="text-lg-medium flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white"
                  expandIconPosition={'start'}
                  expandIcon={(props) => {
                    return props.isActive ? (
                      <ChevronDownIcon className={'stroke-gray-400'} />
                    ) : (
                      <ChevronRightIcon className={'stroke-gray-400'} />
                    )
                  }}>
                  <Panel
                    style={{ border: '1px solid #EAECF0' }}
                    className="bg-gray-50 flex flex-col gap-2"
                    header={
                      <div
                        key={service?.general_service_id}
                        className="flex items-center justify-between gap-4 p-1 bg-gray-50">
                        {/* content */}
                        <div className="grid grid-cols-1 w-full gap-0">
                          <div className="grid grid-cols-2 gap-4 text-xs-medium xl:text-sm-medium text-gray-700">
                            <p>{service?.general_service_name}</p>
                            <p className="whitespace-nowrap text-right">
                              Rp {onRupiah(service.price_total)}
                            </p>
                          </div>
                        </div>

                        {/* remove button */}
                        <div
                          className="w-[20px] cursor-pointer text-center"
                          onClick={(e) => {
                            e.stopPropagation()
                            // console.log('generalService', service.general_service_id)
                            removeCartItem('generalService', service.general_service_id)
                          }}>
                          <XCircleIcon className="stroke-gray-400" />
                        </div>
                      </div>
                    }
                    key={key}>
                    <div>
                      <>
                        <div className="text-xs-regular text-gray-500 pb-2">
                          {service?.sparepart_name !== '' && (
                            <>
                              <p className="text-xs-medium">Sparepart</p>
                              <div key={key} className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
                                <p>{service?.sparepart_name}</p>
                                <div className="flex justify-center">x{service?.quantity}</div>
                                <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                                  {service?.voucher_sparepart_detail?.length > 0 ||
                                  service?.voucher_sparepart_detail !== null
                                    ? service?.voucher_sparepart_detail.map((el, i) => (
                                        <p key={i}>
                                          {el.price_total > 0
                                            ? `- ${
                                                el.discount_type === 'PERCENT'
                                                  ? `(${el?.discount}%)`
                                                  : `Rp. ${onRupiah(el.price_total)}`
                                              }`
                                            : ''}
                                        </p>
                                      ))
                                    : ''}
                                </div>
                                <p className="text-right">
                                  Rp {onRupiah(service?.sparepart_price_total)}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="text-xs-regular text-gray-500 pb-2">
                          <p className="text-xs-medium">Layanan</p>
                          <div className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
                            <p>{service?.general_service_name}</p>
                            <span></span>
                            {
                              <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                                {service?.voucher_service_detail?.length > 0 ||
                                service?.voucher_sparepart_detail !== null
                                  ? service?.voucher_service_detail?.map((el, i) => (
                                      <div key={i}>
                                        {el.price_total > 0
                                          ? `- ${
                                              el.discount_type === 'PERCENT'
                                                ? `(${el?.discount}%)`
                                                : `Rp. ${onRupiah(el.price_total)}`
                                            }`
                                          : ''}
                                      </div>
                                    ))
                                  : ''}
                              </div>
                            }
                            <p className="text-right">
                              Rp {onRupiah(service?.service_price_total)}
                            </p>
                          </div>
                        </div>
                      </>
                    </div>
                  </Panel>
                </Collapse>
              ))}
              <Divider className="my-0" />
              {/* pricing */}
              <div className="grid grid-cols-1 gap-2 text-xs-regular text-gray-500">
                {cartList?.sparepart_general_voucher_discount_total ? (
                  <div className="flex justify-between">
                    <p className="text-xs-medium">Voucher Sparepart</p>
                    <p className="text-xs-semibold text-primary-500">
                      - Rp {onRupiah(cartList?.sparepart_general_voucher_discount_total)}
                    </p>
                  </div>
                ) : null}
                {cartList?.service_general_voucher_discount_total ? (
                  <div className="flex justify-between">
                    <p className="text-xs-medium">Voucher Layanan</p>
                    <p className="text-xs-semibold text-primary-500">
                      - Rp {onRupiah(cartList?.service_general_voucher_discount_total)}
                    </p>
                  </div>
                ) : null}
                <div className="flex justify-between">
                  <p className="text-xs-medium">Sub Total</p>
                  <p className="text-xs-semibold">Rp {onRupiah(cartList?.item_total)}</p>
                </div>

                {cartList?.distance_price !== null ? (
                  <div className="flex justify-between">
                    <p className="text-xs-medium">
                      Harga jarak ({cartList?.start_distance}km - {cartList?.end_distance}km)
                    </p>
                    <p className="text-xs-semibold">
                      Rp{' '}
                      {cartList?.distance_price !== null ? onRupiah(cartList?.distance_price) : 0}
                    </p>
                  </div>
                ) : null}
                {cartList?.admin_fee !== 0 && (
                  <div className="flex justify-between">
                    <p className="text-xs-medium">Biaya Admin</p>
                    <p className="text-xs-semibold">
                      Rp {!isNaN(cartList?.admin_fee) ? onRupiah(cartList?.admin_fee) : 0}
                    </p>
                  </div>
                )}
                {cartList?.company_discount && (
                  <div className="flex justify-between">
                    <p className="text-xs-medium">
                      Company Discount{' '}
                      {cartList?.company_discount_type === 'PERCENT'
                        ? `${cartList?.company_discount}%`
                        : null}
                    </p>
                    <p className="text-xs-semibold text-primary-500">
                      - Rp{' '}
                      {!isNaN(cartList?.company_discount_total)
                        ? onRupiah(cartList?.company_discount_total)
                        : 0}
                    </p>
                  </div>
                )}
                <div className="flex justify-between">
                  <p className="text-xs-medium">Pajak {cartList?.tax_percentage}%</p>
                  <p className="text-xs-semibold">
                    Rp {!isNaN(cartList?.tax) ? onRupiah(cartList?.tax) : 0}
                  </p>
                </div>
              </div>
              <Divider className="my-0" />
              {/* total */}
              <div className="flex justify-between text-gray-500">
                <p className="text-xs-medium">Total Bayar</p>
                <p className="text-xs-semibold">
                  Rp {!isNaN(cartList?.price_total) ? onRupiah(cartList?.price_total) : 0}
                </p>
              </div>
              {/* voucher */}
              {selectedVoucher?.length > 0 ? (
                <div
                  onClick={() => handleModalVoucherOpen()}
                  className="cursor-pointer p-2 flex justify-between border-2 border-dotted border-primary-300 items-center bg-primary-50 rounded-[4px]">
                  <div className="flex flex-col gap-1 justify-center items-start">
                    <p className="text-sm-medium text-primary-700">
                      {selectedVoucher?.length} Voucher Terpasang
                    </p>
                    <p className="text-primary-500 text-sm-regular">
                      {selectedVoucher?.map((voucher) => voucher?.voucher_code).join(',')}
                    </p>
                  </div>
                  <div
                    className="w-[20px] cursor-pointer text-center"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleModalVoucherOpen()
                    }}>
                    <ArrowRightIcon className="stroke-primary-400" />
                  </div>
                </div>
              ) : (
                <div
                  className="cursor-pointer p-3 flex justify-between border-2 border-dotted border-gray-300 items-center bg-gray-100 rounded-[4px]"
                  onClick={() => handleModalVoucherOpen()}>
                  <p className="text-sm-regular text-gray-500">
                    %<span className="ml-3">Apply Kode Voucher</span>
                  </p>
                  <div>{<ChevronRightIcon className={'stroke-gray-700'} />}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </CardSectionHeader>
      {/* Modal Voucher */}
    </div>
  )
}

export default CardServiceCart
