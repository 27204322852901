import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import React from 'react'

const ModalDeleteEmail = ({ show, onCancel, onSubmit, data }) => {
  return (
    <ModalLayout
      headerTitle={'Konfirmasi Hapus Email'}
      onCancel={onCancel}
      openModal={show}
      className="max-w-[500px] w-full">
      <p className="text-gray-900 text-sm-regular">
        Apakah Anda yakin ingin menghapus email <span className="font-medium">{data?.email}</span>{' '}
        ini? Data yang Anda hapus tidak dapat dipulihkan kembali.
      </p>
      <div className="flex justify-end items-center gap-2 mt-4">
        <CustomButton type="plain" label={'Batal'} onClick={onCancel} />
        <CustomButton label={'Hapus'} onClick={onSubmit} />
      </div>
    </ModalLayout>
  )
}

export default ModalDeleteEmail
