import { debounce, replace } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { getVoucherList } from '../service'
import { showErrorMessage, showSuccessMessage, showWarningMessage } from 'utils/toastMessage'

const INITIAL_TOTAL_PACKET_LAYANAN = {
  layanan: 0,
  sparepart: 0
}
const LIMIT_SPAREPART_VOUCHER = 2
const LIMIT_LAYANAN_VOUCHER = 2

const useVoucher = (serviceType, serviceTotalPrice) => {
  const [voucherList, setVoucherList] = useState([])
  const [selectedVoucher, setSelectedVoucher] = useState([])
  const [selectedVoucherIds, setSelectedVoucherIds] = useState({})
  const [totalLayananSparepartVoucher, setTotalLayananSparepartVoucher] = useState({
    layanan: 0,
    sparepart: 0
  })
  const [temporarySelectedVoucher, setTemporarySelectedVoucher] = useState([])
  const [temporarySelectedVoucherIds, setTemporarySelectedVoucherIds] = useState({})
  const [temporaryTotalLayananSparepartVoucher, setTemporaryTotalLayananSparepartVoucher] =
    useState({
      layanan: 0,
      sparepart: 0
    })
  const [temporaryVoucherCode, setTemporaryVoucherCode] = useState('')
  const [voucherFilter, setVoucherFilter] = useState({
    total_price: null,
    type: serviceType,
    voucher_code: ''
  })

  const [modalVoucherOpen, setModalVoucherOpen] = useState(false)
  const [modalDetailCoupon, setModalDetailCoupon] = useState(false)
  const [selectedDetailCoupon, setSelectedDetailCoupon] = useState(null)

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 300),
    []
  )
  const { refetch } = useQuery({
    queryKey: ['getVoucherList'],
    queryFn: () => getVoucherList(voucherFilter),
    onSuccess: (data) => {
      if (voucherFilter?.voucher_code == '') {
        let newData = data?.filter((voucher) => {
          let currentDate = new Date()

          return new Date(voucher?.expiry_date).getTime() > currentDate.getTime()
        })
        setVoucherList(newData)
      } else {
        // VALIDATE VOUCHER CODE
        if (data?.length === 0) {
          return showErrorMessage('Kode kupon salah, silahkan cek kode kupon kembali')
        }
        if (!data[0]?.is_available) {
          return showErrorMessage('Kode kupon tidak dapat digunakan')
        } else if (temporarySelectedVoucherIds?.[data[0]?.id]) {
          return showWarningMessage('Kode kupon sudah dipakai')
        } else if (checkIsVoucherValid(data[0]?.is_available, data[0]?.discount_for)) {
          handleTemporarySelectedVoucher(data[0])
          handleVoucherFilter('voucher_code', '')
          setTemporaryVoucherCode('')
        } else {
          return showErrorMessage('Kupon yang Anda pilih sudah mencapai batas')
        }
      }
    },
    refetchOnWindowFocus: false
  })

  function handleModalVoucherOpen() {
    setModalVoucherOpen(true)
    handleVoucherFilter('total_price', serviceTotalPrice)
    if (selectedVoucher.length > 0) {
      setTemporarySelectedVoucher([...selectedVoucher])
      setTemporarySelectedVoucherIds({ ...selectedVoucherIds })
      setTemporaryTotalLayananSparepartVoucher({ ...totalLayananSparepartVoucher })
    }
  }
  function handleModalVoucherClose() {
    handleVoucherFilter('voucher_code', '')
    setModalVoucherOpen(false)
  }

  function handleModalDetailCoupon() {
    if (modalDetailCoupon) {
      setModalDetailCoupon(false)
      setSelectedDetailCoupon(null)
      setModalVoucherOpen(true)
    } else {
      setModalDetailCoupon(true)
      setModalVoucherOpen(false)
    }
  }
  function handleSelectedDetailCoupon(data) {
    setSelectedDetailCoupon(data)
  }

  function applySelectedVouchers() {
    setSelectedVoucher([...temporarySelectedVoucher])
    setSelectedVoucherIds({ ...temporarySelectedVoucherIds })
    setTotalLayananSparepartVoucher({ ...temporaryTotalLayananSparepartVoucher })
    handleModalVoucherClose()
    resetTemporarySelectedVouchers()
    if (temporarySelectedVoucher.length === 0) {
      showSuccessMessage('Kupon berhasil dibatalkan')
    } else {
      showSuccessMessage('Kupon berhasil terpasang')
    }
  }

  function handleVoucherCode() {
    handleVoucherFilter('voucher_code', temporaryVoucherCode)
  }

  function handleVoucherFilter(name, value) {
    let newValue = replace(value, ' ', '')
    setVoucherFilter({ ...voucherFilter, [name]: newValue })
    return debouncedChangeHandler()
  }
  function checkIsVoucherValid(isAvailable, discountFor) {
    // 1 buat diskon jasa, 2 buat spare part, 3 buat keduanya
    let newTemporaryTotalLayananSparepartObj = { ...temporaryTotalLayananSparepartVoucher }
    // kalau voucher g available return false
    if (!isAvailable) {
      return false
    }

    // CHECKING IF VOUCHER ADDED WILL TOTAL EXCEED LIMIT OR NOT
    if (discountFor === 4) {
      newTemporaryTotalLayananSparepartObj.layanan += 2
      newTemporaryTotalLayananSparepartObj.sparepart += 2
    }
    if (discountFor === 3) {
      newTemporaryTotalLayananSparepartObj.layanan += 1
      newTemporaryTotalLayananSparepartObj.sparepart += 1
    }
    if (discountFor === 2) {
      newTemporaryTotalLayananSparepartObj.sparepart += 1
    }
    if (discountFor === 1) {
      newTemporaryTotalLayananSparepartObj.layanan += 1
    }
    if (
      newTemporaryTotalLayananSparepartObj.layanan > LIMIT_LAYANAN_VOUCHER ||
      newTemporaryTotalLayananSparepartObj.sparepart > LIMIT_SPAREPART_VOUCHER
    ) {
      return false
    }
    return true
  }

  function handleTemporarySelectedVoucher(voucher) {
    //discount_for 1 buat diskon jasa, 2 buat spare part, 3 buat keduanya
    let newTotalLayananSparepartObj = { ...temporaryTotalLayananSparepartVoucher }
    // VALIDATE TOGGLE SELECTED VOUCHER

    // IF VOUCHER NOT SELECTED
    if (!temporarySelectedVoucherIds[voucher?.id]) {
      if (voucher?.discount_for === 3) {
        newTotalLayananSparepartObj.layanan += 1
        newTotalLayananSparepartObj.sparepart += 1
      } else if (voucher?.discount_for === 2) {
        newTotalLayananSparepartObj.sparepart += 1
      } else if (voucher?.discount_for === 1) {
        newTotalLayananSparepartObj.layanan += 1
      } else if (voucher?.discount_for === 4) {
        newTotalLayananSparepartObj.layanan += 2
        newTotalLayananSparepartObj.sparepart += 2
      }
      setTemporarySelectedVoucher((prev) => [...prev, voucher])
      setTemporarySelectedVoucherIds((prev) => ({ ...prev, [voucher.id]: true }))
    } else {
      //IF VOUCHER ALREADY SELECTED
      let newSelectedVoucherArr = temporarySelectedVoucher.filter(
        (selectedVoucher) => selectedVoucher?.id !== voucher?.id
      )
      let newSelectedVoucherIdsObj = { ...temporarySelectedVoucherIds }
      delete newSelectedVoucherIdsObj[voucher?.id]

      if (voucher?.discount_for === 3) {
        newTotalLayananSparepartObj.layanan -= 1
        newTotalLayananSparepartObj.sparepart -= 1
      } else if (voucher?.discount_for === 2) {
        newTotalLayananSparepartObj.sparepart -= 1
      } else if (voucher?.discount_for === 1) {
        newTotalLayananSparepartObj.layanan -= 1
      } else if (voucher?.discount_for === 4) {
        newTotalLayananSparepartObj.layanan -= 2
        newTotalLayananSparepartObj.sparepart -= 2
      }

      setTemporarySelectedVoucher([...newSelectedVoucherArr])
      setTemporarySelectedVoucherIds({ ...newSelectedVoucherIdsObj })
    }
    setTemporaryTotalLayananSparepartVoucher({ ...newTotalLayananSparepartObj })
  }
  function resetVoucherState() {
    resetSelectedVoucher()
    resetTemporarySelectedVouchers()
    setVoucherFilter({
      total_price: null,
      type: serviceType,
      voucher_code: ''
    })
    setSelectedDetailCoupon(null)
  }
  function resetSelectedVoucher() {
    setSelectedVoucher([])
    setSelectedVoucherIds({})
    setTotalLayananSparepartVoucher({ ...INITIAL_TOTAL_PACKET_LAYANAN })
  }
  function resetTemporarySelectedVouchers() {
    setTemporarySelectedVoucher([])
    setTemporarySelectedVoucherIds({})
    setTemporaryTotalLayananSparepartVoucher({ ...INITIAL_TOTAL_PACKET_LAYANAN })
  }
  return {
    handleVoucherFilter,
    voucherFilter,
    voucherList,
    selectedVoucher,
    temporarySelectedVoucher,
    temporarySelectedVoucherIds,
    handleTemporarySelectedVoucher,
    resetTemporarySelectedVouchers,
    modalVoucherOpen,
    handleModalVoucherOpen,
    modalDetailCoupon,
    handleModalDetailCoupon,
    handleVoucherCode,
    handleSelectedDetailCoupon,
    selectedDetailCoupon,
    handleModalVoucherClose,
    selectedVoucherIds,
    resetVoucherState,
    checkIsVoucherValid,
    applySelectedVouchers,
    temporaryVoucherCode,
    setTemporaryVoucherCode
  }
}

export default useVoucher
